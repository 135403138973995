const currentUrl = window.location.href

export const translation = {
  action: 'Действие',
  add: 'Добавете',
  'add-retranslators': 'Добавете повторител',
  'add-terminal': 'Добавяне на терминали',
  addCompany: 'Добавете фирма',
  'addCompanyTransalter.addUser': 'ДОБАВЯНЕ НА ПОТРЕБИТЕЛ',
  'addCompanyTransalter.cancel': 'ОТМЕНЯНЕ',
  'addCompanyTransalter.description': 'Добавяне на множество терминали при преминаване към нов ред.',
  'addCompanyTransalter.error.enterEmail': 'Моля, въведете валиден имейл адрес',
  'addCompanyTransalter.error.enterName': 'Въведете потребителското си име',
  'addCompanyTransalter.error.enterPassword': 'въведете паролата',
  'addCompanyTransalter.error.name': 'Попълнете полето "Име на фирмата".',
  'addCompanyTransalter.error.password': 'Несъответствие на паролата',
  'addCompanyTransalter.error.repeatPassword': 'паролите трябва да съвпадат',
  'addCompanyTransalter.error.time': 'Попълнете полето "Часова зона".',
  'addCompanyTransalter.password': 'Парола:',
  'addCompanyTransalter.repeatPassword': 'Потвърдете паролата:',
  'addCompanyTransalter.save': 'Запазване',
  'addCompanyTransalter.selectCompany': 'Преминете към избрана компания',
  'addLocatorTranslate.selectDateFrom': 'Изберете начална дата',
  'addLocatorTranslate.selectDateTo': 'Изберете дата на завършване',
  'addLocatorTranslate.selectObject': 'Изберете обект',
  'add_parametrs.accepted': 'Прието',
  'add_parametrs.add_parametrs': 'Добавяне на параметри',
  'add_parametrs.good': 'Глоба',
  'add_parametrs.no_accepted': 'Не се приема',
  'add_parametrs.parametrs_available': 'Налични параметри',
  'add_parametrs.parametrs_time': 'Следните параметри са добавени успешно:',
  'add_parametrs.protocol': 'протокол',
  'add_parametrs.send_parametrs': 'Изпратете параметри за добавяне',
  'add_parametrs.support': 'Свържете се с поддръжката, за да добавите следните опции (тъй като не могат да бъдат добавени)',
  'admin-panel': 'Админ. ',
  'admin-panel.company': 'Компания',
  'admin-panel.download-finance': 'Изтеглете отчета',
  'admin-panel.finance': 'Финансов доклад',
  'admin-panel.is-company': 'Влез в компания!',
  'admin-panel.module': 'Модул',
  'admin-panel.period': 'Период',
  'admin-panel.show': 'Покажи',
  'admin-panel.timezone': 'Часова зона',
  'admin-panel.title': 'Административен панел',
  'adminPanelTranlsater.acceptDeleteTemplate': 'Сигурни ли сте, че искате да изтриете шаблона?',
  'adminPanelTranlsater.addCompany': 'Добавете фирма',
  'adminPanelTranlsater.cancel': 'Отказ',
  'adminPanelTranlsater.cashType.card': 'от картата',
  'adminPanelTranlsater.cashType.deposit': 'ръководство',
  'adminPanelTranlsater.cashType.trial': 'пробна версия',
  'adminPanelTranlsater.cashType.withdrawal': 'тарифно плащане',
  'adminPanelTranlsater.command': 'Екип',
  'adminPanelTranlsater.company': 'Компания',
  'adminPanelTranlsater.copyObject': 'Копиране на обект',
  'adminPanelTranlsater.copyTemplate': 'Копиране на шаблон',
  'adminPanelTranlsater.date': 'дата',
  'adminPanelTranlsater.delete': 'Изтрий',
  'adminPanelTranlsater.enterEmail': 'въведете имейл',
  'adminPanelTranlsater.grade': 'Степен',
  'adminPanelTranlsater.importKML': 'Импортиране от KML',
  'adminPanelTranlsater.importWialon': 'Внос от Wialon',
  'adminPanelTranlsater.invite': 'Покани',
  'adminPanelTranlsater.message': 'Съобщение',
  'adminPanelTranlsater.name': 'Име',
  'adminPanelTranlsater.notData': 'Няма данни',
  'adminPanelTranlsater.numberType': '№ Вид',
  'adminPanelTranlsater.object': 'Предмет',
  'adminPanelTranlsater.period': 'Период',
  'adminPanelTranlsater.phone': 'Телефон',
  'adminPanelTranlsater.protocol': 'протокол',
  'adminPanelTranlsater.recoveryObject': 'Възстановяване на обект',
  'adminPanelTranlsater.total': 'Сума',
  'adminPanelTranlsater.type': 'Тип',
  'adminPanelTranlsater.typeTerminal': 'Тип терминал',
  'adminPanelTranlsater.user': 'Потребител',
  'adminPanelTranlsater.wrongEmail': 'Грешен имейл',
  'admintariff.accept': 'Потвърдете',
  'admintariff.cancel': 'Отказ',
  'admintariff.changetitle': 'Промяна на тарифата',
  'admintariff.company': 'Компания',
  'admintariff.date': 'Следваща дата на плащане',
  'admintariff.days': 'дни',
  'admintariff.for': 'отзад',
  'admintariff.historyobjects': 'обекти',
  'admintariff.newtariff': 'Нова тарифа',
  'admintariff.object': 'обект',
  'admintariff.objects': 'обекти всеки',
  'admintariff.objectsnumber': 'Брой обекти',
  'admintariff.oldtariff': 'Стара тарифа',
  'admintariff.paymentdate': 'дата за плащане',
  'admintariff.period': 'Период на плащане',
  'admintariff.price': 'Цена на един обект',
  'admintariff.refill': 'Допълнете',
  'admintariff.tariff': 'Оценете',
  already_deleted_elements: 'елементи са премахнати',
  'annotation.additional': 'Допълнително:',
  'annotation.data_date': 'Дата на получаване на данни:',
  'annotation.gps_data': 'GPS данни:',
  'annotation.last_params': 'Последни данни:',
  'annotation.reports': 'Доклади:',
  'annotation.sensors': 'Сензори:',
  'annotationCameraTranslater.grid': 'Нет',
  'annotationCameraTranslater.object': 'Обект:',
  'annotationCameraTranslater.ribbon': 'Панделка',
  'annotationCameraTranslater.see': 'Виж',
  'annotationCameraTranslater.video': 'Видео',
  'annotationCameraTranslater.videoRegister': 'Видеорекордер:',
  attention: 'внимание',
  author: 'Автор',
  back: 'обратно',
  blocking: 'Блокиране',
  'company.blocked': 'Фирмата е блокирана',
  'company.balance': 'Баланс:',
  'company.history_pay': 'История на отписването',
  'company.go.pay': 'Плащане',
  'company.blocked.text1': 'Достъпът до фирмата е блокиран. ',
  'company.blocked.text2': 'за възобновяване на достъпа.',
  'company.blocked.text3': 'Достъпът до фирмата е блокиран. ',
  'btn.cancel': 'Отказ',
  'btn.create': 'Създавайте',
  'btn.delete': 'Изтрий',
  'btn.editlist': 'РЕДАКТИРАНЕ НА СПИСЪКА',
  'btn.finisheditlist': 'ПРИКЛЮЧВАНЕ НА РЕДАКТИРАНЕТО',
  'btn.save': 'Запазване',
  'btn.select': 'Избирам',
  'btn.update': 'Актуализация',
  cancel: 'Отказ',
  'catalog.user': 'Ръководство на потребителя',
  change: 'промяна',
  close: 'Близо',
  code: 'Код',
  'come.back': 'Връщане',
  'command-terminal': 'Екип',
  companies: 'Компании',
  'companiesListTranslater.cancel': 'Отказ',
  'companiesListTranslater.copiedObject': 'копиране на обект.',
  'companiesListTranslater.copyObject': 'Копиране на обект',
  'companiesListTranslater.save': 'Запазване',
  'companiesListTranslater.selectCompany': 'Изберете компанията, към която искате да се присъедините',
  'company.accounting_type': 'Вид счетоводство:',
  'company.add_card_cost_note': 'За да добавим карта за автоматично плащане, ще трябва да ви таксуваме. ',
  'company.autopayment': 'Автоматично плащане',
  'company.autopayment_on': 'Активиране на автоматично плащане',
  'company.autopayment_unavailable_with_mir': 'Автоматичното плащане временно не е налично с карти MIR',
  'company.by_number_objects': 'По брой обекти',
  'company.by_number_objects_sent_data': 'По броя на обектите, изпращащи данни',
  'company.cancel': 'Отказ',
  'company.card_add_for_autopayments': 'Добавяне на карта за автоматично плащане',
  'company.change_card_autopayment': 'Смяна на карта за автоматично плащане',
  'company.change_card_cost_note': 'За да промените вашата карта за автоматично плащане, ще трябва да дебитираме 1 рубла от вас. ',
  'company.choose_payment_system': 'Изберете платежна система',
  'company.continue': 'продължи',
  'company.cost_one_object': 'Цена на 1 обект:',
  'company.date-format': 'Формат на датата',
  'company.download_offer': 'Изтеглете оферта',
  'company.exitTime': 'Излезте след n минути неактивност',
  'company.integrator': 'Дилър',
  'company.last_date_widget': 'Час на последната дата в уиджета',
  'company.last_date_widget_note': 'Посочете кой формат на часа ще се използва, когато избирате последната дата в диапазон от време (в изпълнимия модул за избор на дата).',
  'company.max_number_objects': 'Макс. ',
  'company.minimum_payment_amount': 'Минималната сума за плащане е 1 rub.',
  'company.name': 'Име',
  'company.no': 'Не',
  'company.passwordLabel': 'Режим на подобрена защита',
  'company.passwordLabelText1': 'За да се увеличи сигурността, новите потребители ще трябва да създават силни пароли, когато активират този режим. ',
  'company.passwordLabelText2': 'Когато променя паролата на потребител, той трябва да влезе с администраторската парола и след това да зададе своя собствена парола за акаунта. ',
  'company.payment_period': 'Период на плащане:',
  'company.previous_period_amount': 'Сума за предходния период',
  'company.retransmit': 'Повторно излъчване към EFIS',
  'company.select_payment_system': 'Изберете платежна система:',
  'company.start_current_period': 'Начало на текущия период:',
  'company.temporarily_unavailable': 'Временно недостъпно',
  'company.time-format': 'Времеви формат',
  'company.timeZone': 'Часова зона',
  'company.tooltip': '0 или празно поле - няма да има изход поради бездействие',
  'company.use_card_for_autopayments': 'Използвайте карта за автоматично плащане',
  'company.visa_or_mastercard': 'Visa или Mastercard',
  company_system_mir: 'MIR система',
  confirm: 'Потвърдете',
  'content.object_last_state_move': 'Последно състояние: обект в движение',
  'content.object_last_state_stay': 'Последно състояние: обектът стои',
  'content.object_move': 'Обект в движение',
  'content.object_stay': 'Обектът си струва',
  copy: 'IMEI копиран!',
  'copyReportTemplateTranslater.cancel': 'Отказ',
  'copyReportTemplateTranslater.copy': 'копиране на шаблон.',
  'copyReportTemplateTranslater.save': 'Запазване',
  'copyReportTemplateTranslater.selectCompany': 'Изберете компанията, към която искате да се присъедините',
  'copyReportTemplateTranslater.title': 'Копиране на шаблон',
  create: 'Създавайте',
  'createPayment.amount': 'Размер на плащането',
  'createPayment.company': 'Компания',
  'createPayment.createPayment': 'Създайте плащане',
  'createPayment.error.createPayment': 'Плащането е създадено',
  'createPayment.error.enterAmount': 'Попълнете полето "Сума за плащане".',
  'createPayment.error.enterCompany': 'Попълнете полето "Фирма".',
  'createPayment.error.error': 'Грешка',
  'createPayment.numberPayment': 'Плащане №',
  'createPayment.title': 'Създайте плащане',
  'createPayment.upBalance': 'Тази операция ще увеличи баланса на избраната компания',
  'dealerTranslater.INN': 'ТИН',
  'dealerTranslater.address': 'Адрес',
  'dealerTranslater.back': 'обратно',
  'dealerTranslater.confirmApp': 'приложение',
  'dealerTranslater.confirmContacts': 'Контакти:',
  'dealerTranslater.confirmDescAdmin': 'Описание на Административния панел',
  'dealerTranslater.confirmDescBill': 'Описание на тарифата',
  'dealerTranslater.confirmDescUi': 'Описание на интерфейса',
  'dealerTranslater.confirmDownload': 'Изтегли',
  'dealerTranslater.confirmFastStart': 'Бърз старт',
  'dealerTranslater.confirmGoToApp': 'Отидете на приложението',
  'dealerTranslater.confirmHeader': 'Регистрацията на нов интегратор е завършена',
  'dealerTranslater.confirmMaterials': 'Допълнителни материали:',
  'dealerTranslater.confirmMsg': 'В близко бъдеще ще получите имейл с връзка към чата на телеграмата за поддръжка; в чата те ще отговорят на всички ваши въпроси и ще ви помогнат с миграцията от друга платформа.',
  'dealerTranslater.email': 'електронна поща',
  'dealerTranslater.enter': 'Посочете',
  'dealerTranslater.enterCodeFromImage': 'За да завършите регистрацията, въведете кода от картинката:',
  'dealerTranslater.enterEmail': 'Въведете своя имейл',
  'dealerTranslater.enterINN': 'Въведете вашия TIN',
  'dealerTranslater.enterNameCompany': 'Въведете името на фирмата',
  'dealerTranslater.enterPassword': 'въведете паролата',
  'dealerTranslater.enterPhone': 'Въведете Вашият телефонен номер',
  'dealerTranslater.enterSurname': 'Въведете пълното си име',
  'dealerTranslater.goRegister': 'Регистрирам',
  'dealerTranslater.nameCompany': 'Име на компанията',
  'dealerTranslater.next': 'По-нататък',
  'dealerTranslater.password': 'Парола',
  'dealerTranslater.register': 'Регистрация на нов дилър',
  'dealerTranslater.registerNewDealer': 'Регистрация на нов дилър',
  'dealerTranslater.rewritePassword': 'Потвърдете паролата*',
  'dealerTranslater.services': 'Описание на услугите',
  'dealerTranslater.surname': 'Пълно име',
  'dealerTranslater.tariff_description': 'Описание на тарифата',
  'dealerTranslater.typeOrganization': 'Тип организация',
  delete: 'Изтрий',
  'demoTourTranslater.interface': 'Видео преглед на интерфейса',
  'demoTourTranslater.link': 'Свържете се с разработчиците',
  'demoTourTranslater.modules': 'Модули',
  'demoTourTranslater.next': 'По-нататък',
  'demoTourTranslater.search': 'Търсене, известия и настройки',
  'demoTourTranslater.text': 'Стремим се да предоставим на потребителите ясен и пълен изглед на обектите, така че нашето приложение поставя голям акцент върху взаимодействието с картата, тъй като тя е основният инструмент за визуализация.',
  'demoTourTranslater.title': 'Вашият основен инструмент е карта',
  'demoTourTranslater.video': 'Видео преглед на интерфейса',
  'demoTourTranslater.watchVideoYouTube': 'Гледайте уводно видео в YouTube',
  'demoTourTranslater.workMap': 'Работа с картата',
  description: 'Описание',
  details: 'Допълнително',
  'edit-retranslators': 'Редактиране на повторителя',
  'edit-terminal': 'Редактиране на терминал',
  'editTarifTranslater.amount': 'Сума:',
  'editTarifTranslater.amountPer1Object': 'Цена за 1 обект',
  'editTarifTranslater.changePeriod': 'Променихте периода на плащане, така че следващата дата на плащане се промени от',
  'editTarifTranslater.dateNextPayment': 'Следващата дата на плащане не може да е в миналото. ',
  'editTarifTranslater.dateStart': 'Начална дата на тарифата:',
  'editTarifTranslater.info': 'Размерът на плащането за периода на плащане се формира по формулата: Макс. ',
  'editTarifTranslater.info_2': 'Сумата на плащането за периода на плащане се формира по формулата: Брой обекти, изпратили данни за периода, умножен по цената на 1 обект',
  'editTarifTranslater.max': 'Макс. ',
  'editTarifTranslater.nextPayment': 'Следното тарифно плащане:',
  'editTarifTranslater.periodStart': 'Начало на периода',
  'editTarifTranslater.setBeforeDate': 'Задавате началото на тарифата на минала дата, поради това датата на следващото плащане се е променила от',
  'editTarifTranslater.setStartTarif': 'Задали сте тарифата да стартира на бъдеща дата, поради това компанията ще бъде в пробен период от',
  'editTarifTranslater.type': 'Счетоводен тип',
  email: 'поща',
  email_confirmation: 'Имейл потвърждение',
  error: 'Грешка',
  'error.429': 'Твърде много опити, опитайте отново по-късно.',
  'error.502': 'За съжаление сървърът е временно недостъпен. ',
  events: 'Събитие',
  'events.consumed': 'Разход на гориво, л',
  'events.create': 'Създайте събитие',
  'events.datetime': 'Време за среща',
  'events.description': 'Описание',
  'events.deviation_minutes': 'Отклонение, мин',
  'events.duration_hours': 'Продължителност ч',
  'events.duration_minutes': 'Продължителност, мин',
  'events.filled': 'Заредено гориво, л',
  'events.finish_date': 'Край',
  'events.guaranteed': 'Гаранция',
  'events.millage': 'Пробег км.',
  'events.motohours': 'Часове на двигателя, ч',
  'events.price': 'Цена',
  'events.requiredFields': 'Задължителни полета',
  'events.service_name': 'Обслужване',
  'events.start_date': 'Започнете',
  'events.stolen': 'Източено гориво, л',
  'events.type_duration_hours': 'Продължителност, ч',
  'events.type_name': 'Тип събитие',
  'events.update': 'Редактиране на събитието',
  'events.user_id': 'Шофьор',
  'events.validation.datetime': 'Въведете дата и час',
  'events.validation.number': 'Трябва да е число, по-голямо от 0',
  'events.validation.required': 'Задължително поле',
  'events.show': 'Вижте събитието',
  'events.imageview.use.keyboard': 'Използвайте ключовете',
  'events.imageview.use.keyboard1': 'за превключване между изображения',
  'events.imageview.images': 'изображения:',
  'events.imageview.maxsize.msg': 'Не можете да добавяте изображения, по-големи от 10 MB.',
  'events.validation.driver': 'Изберете драйвер',
  excel: 'Excel',
  'fast_track.close': 'Скриване на бърза следа',
  'feedback.description': 'Споделете с нас опита си от използването на нашия продукт',
  'feedback.fill_area': 'Попълнете полето',
  'feedback.message_send': 'Вашата заявка е изпратена. ',
  'feedback.question_offer': 'Въпрос Предложение',
  'feedback.send': 'Изпратете',
  'feedback.yours': 'вашия отзив',
  'feedbackTranslater.addSomeTerminals': 'Добавяне на множество терминали при преминаване към нов ред.',
  'feedbackTranslater.error.name': 'Попълнете полето "Име на фирмата".',
  'feedbackTranslater.error.time': 'Попълнете полето "Часова зона".',
  'feedbackTranslater.save': 'Запазване',
  'feedbackTranslater.selectCompany': 'Преминете към избрана компания',
  fill_field: 'Търсене по обекти',
  'formula.add': 'Добавете',
  'formula.errors.formula': 'Въведете формулата',
  'formula.errors.name': 'Въведете заглавието',
  'formula.extra_fields_sensors': 'Добавете допълнително ',
  'formula.tooltip': 'Във формулата можете да използвате аритметичните операции +,-,/,*, скоби )(, всякакви числа',
  'geozones.add': 'Добавете',
  'geozones.blue': 'Син',
  'geozones.color': 'Цвят',
  'geozones.create': 'Добавяне на геозони',
  'geozones.delete': 'Изтрий',
  'geozones.edit': 'Редактиране на геозона',
  'geozones.editor': 'Редактиране',
  'geozones.errors.draw_circle': 'Начертайте кръг!',
  'geozones.errors.min_dots_line': 'Трябват ви поне 2 точки за линията!',
  'geozones.errors.min_dots_polygon': 'Трябват ви поне 3 точки за полигона!',
  'geozones.errors.name': 'Въведете вашето име!',
  'geozones.errors.put_marker': 'Поставете маркера на картата!',
  'geozones.errors.width': 'Моля, въведете валидна ширина!',
  'geozones.figure': 'Фигура',
  'geozones.green': 'Зелено',
  'geozones.grey': 'Сив',
  'geozones.hectar': 'ха',
  'geozones.km': 'км',
  'geozones.library': 'Директория за геозони',
  'geozones.meter': 'м',
  'geozones.name': 'Име',
  'geozones.orange': 'портокал',
  'geozones.red': 'червен',
  'geozones.square': 'Квадрат:',
  'geozones.type': 'Тип',
  'geozones.unitArea': 'Площни единици:',
  'geozones.use_as_address': 'Използвайте като адрес',
  'geozones.never_cluster': 'Никога не групирайте',
  'geozones.width': 'Дебелина',
  'geozones_lang.geozones_enter': 'Влезте в геозони',
  'geozones_lang.geozones_exit': 'Напускане на геозони',
  'guide.back': 'обратно',
  'guide.company_workplace': 'Работно място на компанията',
  'guide.instructions': 'Инструкции',
  'guide.personal_account': 'Личен акаунт на дилъра',
  'guide.quiestion_offer': 'Въпрос Предложение',
  'guide.short_instruction': 'Кратки инструкции',
  'guide.title': 'справка',
  help: 'справка',
  'helper.admin.text': 'За достъп до админ панела е необходимо да се регистрирате като интегратор или да поискате демонстрация на платформата от наш специалист.',
  'helper.content_text': 'За работа със сензори:',
  'helper.content_text_list.five': 'В сензора можете да изберете параметри и да конфигурирате техните калибрации за това, щракнете върху',
  'helper.content_text_list.four': 'Ще видите списък със сензори в обекта. ',
  'helper.content_text_list.one': '1. Отворете модула “Обекти”.',
  'helper.content_text_list.seven': 'За да направите това, в полето „Формула“ изберете последния елемент „Израз“.',
  'helper.content_text_list.six': 'В допълнение към стандартните формули, можете да създавате свои собствени изрази за параметри с възможност за достъп до необработени данни от сензори.',
  'helper.content_text_list.three': '3. Изберете „Сензори“ в горния ляв ъгъл.',
  'helper.content_text_list.two': '2. Изберете обект, като щракнете върху името му от списъка',
  'helper.description_text': 'В демо режим нямате достъп до административния панел и възможността да създавате/редактирате обекти (обекти, геозони и др.).',
  'helper.footer.account': 'Регистрирайте акаунт',
  'helper.footer.get_free': '(Вземете 30-дневен безплатен пробен период)',
  'helper.list.five.title': 'Административен панел',
  'helper.list.four.title': 'Мониторинг',
  'helper.list.one.content': 'Създавайте и редактирайте обекти. ',
  'helper.list.one.link': 'Видео: "Създаване на обект"',
  'helper.list.one.title': 'Обекти',
  'helper.list.three.title': 'Доклади',
  'helper.list.two.content': 'За да работите със сензори: 1. Отворете модул "Обекти". ',
  'helper.list.two.title': 'Сензори',
  'helper.monitoring.colors.one': '— Данните пристигнаха преди по-малко от 15 минути',
  'helper.monitoring.colors.three': '— Данните пристигнаха преди повече от 30 минути',
  'helper.monitoring.colors.two': '— Данните пристигнаха преди по-малко от 30 минути',
  'helper.monitoring.statuses.four': '— Обектът е в движение',
  'helper.monitoring.statuses.one': '— Обектът стои',
  'helper.monitoring.statuses.text': 'Един обект може да има един от 4 статуса:',
  'helper.monitoring.statuses.three': '— Обектът е в движение',
  'helper.monitoring.statuses.two': '— Предметът стои с включено запалване',
  'helper.monitoring.text_three': 'Също така в мониторинга имате достъп до бързи действия, свързани с обект. ',
  'helper.monitoring.text_two': 'За да можете бързо да разберете преди колко време са пристигнали данните за обекта, добавихме цветна индикация:',
  'helper.objects': 'Създавайте и редактирайте обекти. ',
  'helper.reports.text': 'Генериране на отчет за обект или група от обекти. ',
  'helper.reports.video_base_reports': 'Видео: „Основни отчети“',
  'helper.reports.video_create_template': 'Видео: „Създаване на шаблони“',
  'helper.video': 'Видео: „Създаване на обект“',
  'helper.video_add_sensor': 'Видео: „Добавяне на сензори“',
  'helperTranslater.helper': 'Помощник',
  hide: 'ИЗТРИВА',
  'hide.all': 'Всички елементи',
  'hide.all.text': 'Скриване на всички елементи',
  'hide.events': 'събития',
  'hide.events.text': 'Скриване на събития на картата',
  'hide.geozones': 'Настройки за работа с геозони',
  'hide.geozones.text': 'Скриване на геозони на картата',
  'hide.notifications': 'Настройки за известията',
  'hide.notifications.text': 'Скриване на известията на картата',
  'hide.objects': 'Обекти',
  'hide.objects.text': 'Скриване на обекти на картата',
  'hide.title': 'Видимост на картата',
  'hide.tracks': 'Писти',
  'hide.tracks.direction_of_movement': 'Посока на движение',
  'hide.tracks.group': 'Групирайте, ако разстоянието е по-малко',
  'hide.tracks.signatures': 'Скриване на надписи на песни',
  'hide.tracks.text': 'Скриване на следите на картата',
  'hide.trips': 'Маршрути и полети',
  'hide.trips.text': 'Скриване на маршрути и полети',
  'history.author': 'Автор',
  'history.create': 'Създаване',
  'history.date': 'Време за среща',
  'history.delete': 'Премахване',
  'history.edit': 'Редактиране',
  'history.event': 'Събитие',
  'history.operation': 'Операция',
  'history.title': 'История на промените',
  'importKmlTranslater.error.fileSize': 'Грешка: Файлът трябва да е по-малък от 5 MB.',
  'importKmlTranslater.error.formatKML': 'Файловият формат трябва да е kml',
  'importKmlTranslater.import': 'Импортиране',
  'importKmlTranslater.inCompany': 'Към компанията',
  'importKmlTranslater.selectFile': 'Изберете файл',
  'importKmlTranslater.selectFileKML': 'Изберете географски KML файл',
  'importKmlTranslater.title': 'Импортиране от KML',
  'importWialonTranslater.back': 'обратно',
  'importWialonTranslater.fileObject': 'Изберете файл с обекти от Wialon',
  'importWialonTranslater.findEquipment': 'Открити са следните устройства, изберете протоколите за тях:',
  'importWialonTranslater.findedParametrs': 'Следните параметри са открити, избрани в сензорите, проверете тяхното съответствие. ',
  'importWialonTranslater.import': 'Импортиране',
  'importWialonTranslater.inCompany': 'Към компанията',
  'importWialonTranslater.next': 'По-нататък',
  'importWialonTranslater.selectFile': 'Изберете файл',
  'importWialonTranslater.selectProtocol': 'Изберете протокол',
  'importWialonTranslater.title': 'Внос от Wialon',
  'importWialonTranslater.typeFiles': '.wlp, .xml или zip архив на такива файлове',
  in: 'IN',
  'integratorsTranslater.INN': 'ТИН',
  'integratorsTranslater.addParameter': 'Добавяне на интегратор',
  'integratorsTranslater.address': 'Адрес',
  'integratorsTranslater.cancel': 'Отказ',
  'integratorsTranslater.change': 'промяна',
  'integratorsTranslater.contactPerson': 'Лицето за контакт',
  'integratorsTranslater.delete': 'Изтрий',
  'integratorsTranslater.documentMobileApp': 'Споразумението за мобилно приложение ще се покаже на вашите клиенти',
  'integratorsTranslater.editParameter': 'Редактиране на интегратор',
  'integratorsTranslater.email': 'електронна поща',
  'integratorsTranslater.enterName': 'Въведете заглавието',
  'integratorsTranslater.fieldMiss': 'Попълнете полето',
  'integratorsTranslater.fileSize': 'Размерът на файла не трябва да надвишава 10 MB',
  'integratorsTranslater.idShop': 'ID на магазина',
  'integratorsTranslater.moveOferta': 'Поставете офертата си тук или щракнете, за да изберете',
  'integratorsTranslater.name': 'Име',
  'integratorsTranslater.numberCallCenter': 'Номер на контактния център',
  'integratorsTranslater.ofertaLoaded': 'Офертата е заредена',
  'integratorsTranslater.password': 'Парола',
  'integratorsTranslater.paySystem': 'Платежна система',
  'integratorsTranslater.paymentInforamion': 'Информация за плащане',
  'integratorsTranslater.periodPayment': 'Период на плащане (дни)',
  'integratorsTranslater.priceObject': 'Цена на обект',
  'integratorsTranslater.secretKey': 'Тайният ключ',
  'integratorsTranslater.services': 'Услуги',
  'integratorsTranslater.smsTariff': 'SMS тарифа',
  'integratorsTranslater.tariffDefault': 'Тарифа по подразбиране за нови компании',
  'integratorsTranslater.tariffDefaultQR': 'Скорост по подразбиране за QR регистрации',
  'integratorsTranslater.tariffDescription': 'Описание на тарифата',
  'integratorsTranslater.tryPeriod': 'Пробен период (дни)',
  'integratorsTranslater.typeAccount': 'Счетоводен тип',
  'integratorsTranslater.typeOrganization': 'Тип организация',
  'inviteUserTranslater.back': 'обратно',
  'inviteUserTranslater.enterNumber': 'Въведете телефонния си номер във формат +XXXXXXXXXX или XXXXXXXXXX',
  'inviteUserTranslater.invite': 'Покани',
  'inviteUserTranslater.next': 'По-нататък',
  'inviteUserTranslater.passwordMustBe': 'Паролата трябва да е повече от 5 знака',
  'inviteUserTranslater.register': 'Регистрирам',
  'inviteUserTranslater.wrongNumber': 'Неправилен телефонен номер',
  'journal-events': 'Дневникът на събитията',
  'journal-events.filter': 'Филтър',
  km_hour: 'км/ч',
  'lkDiler.admin_panel.step_1': 'За да създадете фирма в Административния панел, трябва да кликнете върху „Добавяне на фирма“ в модул „Фирми“:',
  'lkDiler.admin_panel.step_2': 'Във формуляра за създаване на компания, който се отваря, трябва да попълните името на компанията, да изберете часовата зона и, ако е необходимо, да добавите 1 или повече терминали и да щракнете върху „Запазване“. ',
  'lkDiler.admin_panel.step_3': 'Оставете избрана опцията „Превключване към избраната компания“ Ще бъдете автоматично отведени до създадената компания.',
  'lkDiler.change_step.step_1': 'По подразбиране в SKIF.PRO новосъздадените компании използват тарифа от 250 рубли на обект и клиентът има възможност да плати за услугата чрез центъра за обработка на компанията SKIF EPP. ',
  'lkDiler.change_step.step_2': 'Изберете желаната фирма от списъка и кликнете върху бутона „Покажи“.',
  'lkDiler.change_step.step_3': 'Щракнете върху реда с тарифата, изберете броя на обектите 0 и цената на един обект 0, щракнете върху потвърждение',
  'lkDiler.change_tariff': 'Промени в тарифите',
  'lkDiler.create_company': 'Създаване на компании',
  'lkDiler.create_company_step.create': 'Създаване на фирма в Админ панел',
  'lkDiler.create_company_step.register': 'Регистрация чрез линк за покана',
  'lkDiler.create_company_step.title': 'Създаване на компании',
  'lkDiler.create_terminal': 'Създаване на терминали',
  'lkDiler.register_link.step_1': 'За да изпратите връзка с покана, трябва да щракнете върху „Покани“ в списъка с вашите компании.',
  'lkDiler.register_link.step_2': 'В полето, което се отваря, трябва да въведете имейл адреса на служителя на компанията, който ще получи поканата, като щракнете върху връзката в писмото, той ще бъде отведен на страницата за регистрация на новата компания. ',
  'lkDiler.start_terminal.create_terminal': 'Създаване на терминали',
  'lkDiler.start_terminal.step_1': 'За да добавите терминали, трябва да отидете в модула терминали във вашия административен панел и да кликнете върху "+"',
  'lkDiler.start_terminal.step_2': 'След това се нуждаете от:',
  'lkDiler.start_terminal.step_3': '1. Изберете компания от списъка с вашите фирми, в която искате да разрешите използването на терминали.',
  'lkDiler.start_terminal.step_4': '2. Въведете един или повече imei терминали, които искате да добавите. ',
  'lkDiler.start_terminal.step_5': '3. Ако терминалите са свързани с парола, можете да я посочите тук.',
  'lkDiler.start_terminal.step_6': '4. Щракнете върху бутона за запазване.',
  'lkDiler.start_terminal.template.step_1': 'В личния акаунт на дилъра можете да създадете командни шаблони за терминали; за да направите това, отидете в административния панел и изберете модула „Справочник на командите“.',
  'lkDiler.start_terminal.template.step_2': 'Щракнете върху „+“, за да добавите команда, в прозореца, който се отваря, изберете типа терминал, изберете или попълнете името на командата и въведете самата команда в полето „Текст на командата“, щракнете върху Запиши',
  'lkDiler.start_terminal.template.title': 'Командни шаблони за терминали',
  'lkDiler.template_command': 'Командни шаблони',
  'lkDiler.title': 'Личен акаунт на дилъра',
  loading: 'Зареждане...',
  'loginPage.accept': 'Приемам правилата и условията, посочени в',
  'loginPage.addObject': 'Добавете обект',
  'loginPage.autoName': 'Име на автомобила:',
  'loginPage.autoType': 'Тип кола:',
  'loginPage.back': 'обратно',
  'loginPage.btn.send': 'Изпратете',
  'loginPage.carName': 'име на колата',
  'loginPage.clickOnStartTheBot-': 'Кликнете върху Старт, ботът връща пощенския идентификатор за Telegram',
  'loginPage.company.name': 'Име на фирмата',
  'loginPage.company.submit': 'Да вляза',
  'loginPage.company.title': 'Избор на фирма',
  'loginPage.confirm.submit': 'Към основния',
  'loginPage.confirm.text1': 'Фирмата е регистрирана.',
  'loginPage.confirm.text2': 'За да потвърдите регистрацията, следвайте връзката в писмото, изпратено на посочения имейл',
  'loginPage.confirm.title': 'Потвърждение',
  'loginPage.dontHaveAccount': 'Аз съм нов потребител на SKIF.PRO2',
  'loginPage.email': 'електронна поща',
  'loginPage.enterCode': 'Въведете шестцифрения код:',
  'loginPage.enterTheTelegramBot': 'Влезте в телеграм бот',
  'loginPage.errors.email': 'Въведете имейл',
  'loginPage.errors.emailError': 'Въведете съществуващ имейл',
  'loginPage.errors.name': 'Въведете вашето име',
  'loginPage.errors.nameCompany': 'Въведете името на фирмата си',
  'loginPage.errors.pass': 'въведете паролата',
  'loginPage.errors.region': 'Изберете регион',
  'loginPage.errors.telegramChatId': 'Въведете пощенски номер за Telegram',
  'loginPage.haveAccount': 'Имам акаунт',
  'loginPage.hint.companyName': 'Изберете фирма',
  'loginPage.hint.lang': 'Изберете език',
  'loginPage.hint.timeZone': 'Изберете часова зона',
  'loginPage.lang': 'език',
  'loginPage.linkTermsOfUse': '"Споразумение с потребителя"',
  'loginPage.links.ifHaveAccount': 'имаш ли акаунт',
  'loginPage.links.ifHaveNotAccount': 'Нямате акаунт?',
  'loginPage.links.login': 'Да вляза',
  'loginPage.links.lostPass': 'Забравена парола?',
  'loginPage.links.register': 'Регистрация',
  'loginPage.login': 'Влизам',
  'loginPage.login.guest': 'Демо влизане',
  'loginPage.login.submit': 'Да вляза',
  'loginPage.login.title': 'Вход',
  'loginPage.mail': 'Твоят имейл',
  'loginPage.name': 'твоето име',
  'loginPage.name.label': 'Име:',
  'loginPage.nameCompany': 'Име на фирмата',
  'loginPage.offer': 'оферта',
  'loginPage.orgName': 'Име на вашата организация:',
  'loginPage.orgName.holder': 'Име на организацията',
  'loginPage.pass': 'Парола',
  'loginPage.passHint': 'Вашата парола се състои от поне 4 знака.',
  'loginPage.password': 'Парола:',
  'loginPage.phoneHolder': 'телефонен номер във формат +XXXXXXXXXXXX',
  'loginPage.recovery.text1': 'За да възстановите акаунта си, отидете на',
  'loginPage.recovery.text2': 'чрез връзката в писмото, изпратено на посочения имейл.',
  'loginPage.recovery.title': 'Възстановяване',
  'loginPage.recovery.toMain': 'Към основния',
  'loginPage.register': 'Регистрирам',
  'loginPage.register.submit': 'Регистрирам',
  'loginPage.register.title': 'Регистрация',
  'loginPage.sixCode': 'шестцифрен код:',
  'loginPage.telegramChatId': 'Пощенски идентификатори за Telegram',
  'loginPage.textTermsOfUse': 'С натискането на "Регистрация" вие приемате условията',
  'loginPage.timeZone': 'Часова зона',
  'loginPage.timeZones': 'Часова зона:',
  'loginPage.userName': 'Потребителско име',
  'loginPage.validation.acceptTerms': 'моля, приемете условията',
  'loginPage.validation.choosecartype': 'Изберете тип машина',
  'loginPage.validation.codeType': 'Шестцифреният код трябва да се състои от главни английски букви и цифри',
  'loginPage.validation.email': 'Въведете своя имейл',
  'loginPage.validation.email.correctform': 'моля, въведете правилен имейл адрес',
  'loginPage.validation.enter6code': 'Трябва да въведете шестцифрен код',
  'loginPage.validation.entercarname': 'Въведете името на машината',
  'loginPage.validation.name': 'Въведете вашето име',
  'loginPage.validation.password': 'Въведете паролата си',
  'loginPage.validation.sixCode': 'Трябва да въведете шестцифрен код:',
  'loginPage.validation.telephone': 'Въведете Вашият телефонен номер',
  'loginPage.validation.tiemzone': 'Изберете часова зона',
  'loginPage.yourpassword': 'твоята парола',
  'loginPass.changePass.adminPass': 'Въведете паролата, получена от администратора',
  'loginPass.changePass.confirmNewPass': 'Потвърдете паролата',
  'loginPass.changePass.current': 'Настояща парола',
  'loginPass.changePass.empty': 'Ако оставите полето празно, ще получите потвърждение за промяната на вашата парола по имейл.',
  'loginPass.changePass.inputNewPass': 'Въведете нова парола',
  'loginPass.changePass.inputPass': 'въведете паролата',
  'loginPass.changePass.newPass': 'нова парола',
  'loginPass.changePass.newPass_confirm': 'Отново нова парола',
  'loginPass.changePass.old_pass': 'Стара парола',
  'loginPass.changePass.repeatNewPass': 'Повтори паролата',
  'loginPass.changePass.submit': 'Да вляза',
  'loginPass.changePass.title': 'Промяна на паролата',
  'loginPass.errors.lengthPass': 'Паролата трябва да е между 5 и 25 знака',
  'loginPass.errors.lengthPass.strongPass.adminUser': 'Паролата трябва да е между 15 и 25 знака и да се състои от поне една главна английска буква, поне една малка английска буква и поне един специален знак (~ ! @ # $ % ^ & * ( ) - = + [ "{ ] " } | : " " , < . > /?)',
  'loginPass.errors.lengthPass.strongPass.regularUser': 'Паролата трябва да е между 8 и 25 знака и да се състои от поне една главна английска буква, поне една малка английска буква и поне един специален знак (~ ! @ # $ % ^ & * ( ) - = + [ "{ ] " } | : " " , < . > /?)',
  'loginPass.errors.newPass': 'Въведете паролата си отново',
  'loginPass.errors.notMatchPass': 'Въведените пароли не съвпадат',
  'loginPass.errors.pass': 'въведете паролата',
  'loginPass.recovery.back': 'обратно',
  'loginPass.recovery.content': 'За да възстановите акаунта си, следвайте връзката в писмото, изпратено по имейл. ',
  'loginPass.recovery.hint': 'На посочения имейл адрес ще бъде изпратено писмо с връзка за възстановяване на паролата.',
  'loginPass.recovery.title': 'Възстановяване на парола',
  logout: 'Излез',
  'mailing.adresses': 'Адресати',
  'mailing.adresses.warning': 'Въведете вашите пощенски адресати!',
  'mailing.chooseGroup': 'Изберете група!',
  'mailing.chooseObject': 'Изберете обект!',
  'mailing.create': 'Създайте бюлетин',
  'mailing.date': 'дата',
  'mailing.daysweek': 'Дни от седмицата',
  'mailing.daysweek.warning': 'Въведете дните от седмицата!',
  'mailing.edit': 'промяна',
  'mailing.enterName': 'Въведете вашето име!',
  'mailing.isActive': 'Дейност',
  'mailing.mailing': 'Бюлетин',
  'mailing.tooltip': 'Датата и часът на изпращане на първото съобщение ще бъдат изчислени в зависимост от избрания период и датата на последното изпращане',
  'mailing.name': 'Име',
  'mailing.object': 'Предмет',
  'mailing.objectGroup': 'Група от обекти',
  'mailing.periodicity': 'Периодичност',
  'mailing.periodicity.warning': 'Въведете честота!',
  'mailing.subject': 'Тема на съобщението',
  'mailing.subject.warning': 'Въведете вашето съобщение!',
  'mailing.template': 'Изберете шаблон',
  'mailing.timeDateSent': 'Дата/час на последно изпращане',
  'mailing.title': 'Директория с пощенски списък',
  'mailing.vehicle': 'Избор на превозно средство',
  'mainPage.back': 'Към вкъщи!',
  'mainPage.sorry': 'За съжаление вашата връзка вече не работи. ',
  'mainPage.title': 'Добре дошли в СКИФ!',
  'map.adrress_not_found': 'Адресът не е намерен',
  'map.bing': 'Bing',
  'map.bingSatellite': 'Bing Satellite',
  'map.googleHybrid': 'Google Hybrid',
  'map.googleRoadMap': 'Пътна карта на Google',
  'map.googleSatelite': 'Google Satellite',
  'map.googleTerrain': 'Google Terrain',
  'map.here': 'Тук',
  'map.osm': 'O.S.M.',
  'map.search': 'Намиране на точен адрес',
  'map.style': 'Стил картичка',
  'map.title': 'Карта',
  'map.yandex': 'Yandex карти',
  'measure.cancel': 'Отказ',
  'measure.clear': 'ЯСНО',
  'measure.description': 'Можете да промените размерите, като преместите точката',
  'measure.distance': 'Разстояние',
  'measure.finish': 'Завършете измерванията',
  'measure.lastPoint': 'Последна точка',
  'measure.line': 'Линия',
  'measure.perimeter': 'Периметър',
  'measure.polygon': 'Многоъгълник',
  'measure.region': 'Регион',
  'measure.square': 'Квадрат',
  'measure.title': 'Измерване на разстояния и площи',
  'menuSelect.geozones': 'Геопрегради',
  'menuSelect.monitoring': 'Мониторинг',
  'menuSelect.notifications': 'Известия',
  'menuSelect.objects': 'Обекти',
  'menuSelect.race': 'Полети',
  'menuSelect.reports': 'Доклади',
  'menuSelect.routes': 'Маршрути',
  'menuSelect.users': 'Потребители',
  monitoring: 'Мониторинг',
  'monitoring.accept': 'Приложи',
  'monitoring.activeFilters': 'Активни филтри',
  'monitoring.add-objects': 'Добавете обекти',
  'monitoring.addFilter': 'Добавете филтър',
  'monitoring.apply_filter': 'Прилагане на филтри',
  'monitoring.change-object': 'Промяна на обекта',
  'monitoring.choose': 'Избирам',
  'monitoring.columns.name': 'Име',
  'monitoring.command_response': 'Отговор',
  'monitoring.commands': 'Екипи',
  'monitoring.commands.columns.command': 'Екип',
  'monitoring.commands.columns.data': 'Време за среща',
  'monitoring.commands.columns.status': 'Статус',
  'monitoring.commands.error_message': 'Попълнете полетата "Избор на обект" и "Команда".',
  'monitoring.commands.exit_number': 'Изходно число',
  'monitoring.commands.object': 'Предмет',
  'monitoring.create-event': 'Създайте събитие',
  'monitoring.date-and-time': 'дата и час',
  'monitoring.datepoint': 'Бях във връзка',
  'monitoring.display_all': 'Покажи всички',
  'monitoring.displayed_objects': 'Показани обекти:',
  'monitoring.distance_group': 'Групиране, ако разстоянието е по-малко от (екранни пиксели):',
  'monitoring.editObject': 'Редактиране на обект',
  'monitoring.enter-description': 'Въведете описание',
  'monitoring.fast-report': 'Бърз отчет',
  'monitoring.fast-track': 'Бърза писта',
  'monitoring.fast_tracks': 'Бързи пътеки',
  'monitoring.fast_tracks.month': 'На месец',
  'monitoring.fast_tracks.today': 'За днес',
  'monitoring.fast_tracks.week': 'През седмицата',
  'monitoring.fast_tracks.yesterday': 'За вчера',
  'monitoring.fast_tracks.custom': 'Друг период',
  'monitoring.filter-journal-events': 'Филтър за журнал на събития',
  'monitoring.filter.customFields': 'Добавете. ',
  'monitoring.filter.driver': 'Шофьор',
  'monitoring.filter.event': 'събития',
  'monitoring.filter.fieldsEmpty': 'Всички полета трябва да бъдат попълнени',
  'monitoring.filter.imei': 'IMEI',
  'monitoring.filter.name': 'Име',
  'monitoring.filter.phoneNumber': 'телефони',
  'monitoring.filter.sensorName': 'Имена на сензори',
  'monitoring.filter.sensorParam': 'Параметър на сензорите',
  'monitoring.filter.sensorType': 'Видове сензори',
  'monitoring.filterMax': 'Не можете да добавите повече от 30 филтъра',
  'monitoring.filters': 'Филтри',
  'monitoring.graph': 'График',
  'monitoring.grouping_on_map': 'Групиране на картата',
  'monitoring.groups.edit': 'Редактиране',
  'monitoring.map_work_settings': 'Настройки на картата',
  'monitoring.maxUnits': 'Не можете да показвате повече от 300 обекта наведнъж; използвайте филтър, за да намалите броя на обектите.',
  'monitoring.object_search': 'Търсене по име на обект',
  'monitoring.objects': 'Обекти',
  'monitoring.objects_grouping': 'Групиране на обекти',
  'monitoring.objects_list': 'Списък на обектите:',
  'monitoring.objects_on_map': 'Обекти на картата:',
  'monitoring.position': 'Позиция',
  'monitoring.registration-event': 'Регистрация на събитието',
  'monitoring.search.custom_fields': 'Добавете. ',
  'monitoring.search.driver': 'Шофьор',
  'monitoring.search.events': 'събития',
  'monitoring.search.groups': 'Групи',
  'monitoring.search.imei': 'IMEI',
  'monitoring.search.name': 'Име',
  'monitoring.search.phonenumber': 'телефони',
  'monitoring.search.sensors_name': 'Имена на сензори',
  'monitoring.search.sensors_param': 'Параметър на сензорите',
  'monitoring.search.sensors_types': 'Видове сензори',
  'monitoring.searchHolder': 'запалване, гориво',
  'monitoring.send-command': 'Ще изпрати команда',
  'monitoring.sendCommand-title': 'Изпратете команда',
  'monitoring.send_command': 'Изпратете команда',
  'monitoring.shape_size': 'Размер на формата:',
  'monitoring.show_address': 'Показване на адреса на обекта',
  'monitoring.show_annotation_on_click': 'Показване на анотация при щракване върху обект в списъка за наблюдение',
  'monitoring.terminal_on': 'Терминалът е свързан',
  'monitoring.to_reports': 'Към отчетите',
  'monitoring.track_all': 'Проследете всичко',
  'monitoring.trips': 'Пътувания',
  'monitoring.type-event': 'Тип събитие',
  'monitoring.view-events': 'Тип събитие',
  'monitoring.violation': 'Нарушение',
  'monitoringListTranslater.withoutgroup': 'Без група',
  name: 'Име',
  'name.column': 'Заглавие на колона',
  'nav.admin': 'Административен панел',
  'nav.geozones': 'Геопрегради',
  'nav.mailing': 'Бюлетин',
  'nav.monitoring': 'Мониторинг',
  'nav.notifications': 'Известия',
  'nav.objects': 'Обекти',
  'nav.reports': 'Доклади',
  'nav.trips': 'Полети',
  'nav.users': 'Потребители',
  'new_units.add.add_field': 'Добавяне на поле',
  'new_units.add.couplings': 'Добавете',
  'new_units.add.groups': 'Добавяне на група',
  'new_units.add.links': 'Създаване на връзка',
  'new_units.add.objects': 'Добавете обект',
  'new_units.add.parameters': 'Добавете опции',
  'new_units.add.service': 'Добавете поддръжка',
  'new_units.add.shifts': 'Добавете',
  'new_units.add.trailers': 'Добавете трейлър',
  'new_units.apply_changes': 'Прилага промени',
  'new_units.approve': 'Потвърдете',
  'new_units.cancel': 'отмени промените',
  'new_units.create': 'Създавайте',
  'new_units.delete': 'Изтрий',
  'new_units.edit': 'редактиране',
  'new_units.footer.cancel': 'Отказ',
  'new_units.footer.save': 'Запазване',
  'new_units.groups.errors.create': 'Ами сега... Възникна проблем при създаването',
  'new_units.groups.errors.delete': 'Възникна грешка при изтриване на групи!',
  'new_units.groups.errors.edit': 'Ами сега... Възникна проблем при редактирането',
  'new_units.groups.errors.name': 'Въведете вашето име!',
  'new_units.groups.errors.validation': 'Попълнете задължителните полета!',
  'new_units.groups.no_groups': 'Не са създадени групи обекти',
  'new_units.groups.search': 'Търсене по група',
  'new_units.groups.search_options.name': 'Име',
  'new_units.groups.search_options.object': 'Предмет',
  'new_units.groups.success.create': 'Групата е създадена успешно!',
  'new_units.groups.success.delete': 'Групите са изтрити успешно!',
  'new_units.groups.success.edit': 'Групата е успешно редактирана!',
  'new_units.groups.table_columns.group_name': 'Име на групата',
  'new_units.groups.table_columns.objects': 'Обекти',
  'new_units.groups.table_columns.users_count': 'Потребители',
  'new_units.links.accessEnd': 'Крайна дата на достъп',
  'new_units.links.accessStart': 'Начална дата на достъп',
  'new_units.links.create': 'Създайте връзка за достъп',
  'new_units.links.description': 'Описание',
  'new_units.links.edit': 'Редактиране на връзката',
  'new_units.links.empty': 'Не е избрано',
  'new_units.links.errors.access_end': 'Изберете дата на завършване!',
  'new_units.links.errors.access_start': 'Изберете начална дата!',
  'new_units.links.errors.create': 'Възникна грешка при създаването на връзката за достъп!',
  'new_units.links.errors.delete': 'Възникна грешка при изтриване на връзки за достъп!',
  'new_units.links.errors.edit': 'Възникна грешка при редактиране на връзката за достъп!',
  'new_units.links.errors.unit': 'Изберете обект!',
  'new_units.links.link': 'Връзка',
  'new_units.links.no_links': 'Списъкът с връзки за достъп е празен',
  'new_units.links.object': 'Предмет',
  'new_units.links.placeholder': 'Дълго описание....',
  'new_units.links.search': 'Търсене по връзки',
  'new_units.links.search_options.object': 'Предмет',
  'new_units.links.search_options.status': 'Статус',
  'new_units.links.status.active_off': 'Активен (изключен)',
  'new_units.links.status.active_on': 'Активен (включен)',
  'new_units.links.status.inactive': 'Неактивен',
  'new_units.links.status.planned': 'Планирано',
  'new_units.links.success.create': 'Връзката за достъп е създадена успешно!',
  'new_units.links.success.create_imei': 'Imei кодът е създаден успешно!',
  'new_units.links.success.delete': 'Връзките за достъп са премахнати успешно!',
  'new_units.links.success.edit': 'Връзката за достъп е успешно редактирана!',
  'new_units.links.success.edit_imei': 'Imei кодът е успешно редактиран!',
  'new_units.links.table_columns.active_from': 'Активен от',
  'new_units.links.table_columns.active_to': 'Активен от',
  'new_units.links.table_columns.object': 'Предмет',
  'new_units.links.table_columns.status': 'Статус',
  'new_units.not_selected': 'не е избрано',
  'new_units.objects.approved': 'ПОТВЪРДЕНО',
  'new_units.objects.create': 'Създаване на обект',
  'new_units.objects.edit': 'Редактиране на обект',
  'new_units.objects.errors.delete': 'Възникна грешка при изтриване на обекти!',
  'new_units.objects.fields.basic.errors.custom_imei': 'Заключете го!',
  'new_units.objects.fields.basic.errors.imei': 'Изберете уникален ID!',
  'new_units.objects.fields.basic.errors.name': 'Въведете вашето име!',
  'new_units.objects.fields.basic.errors.phonenumber': 'Въведете телефона си!',
  'new_units.objects.fields.basic.errors.terminal_type': 'Изберете тип устройство!',
  'new_units.objects.fields.basic.errors.type': 'Изберете тип обект!',
  'new_units.objects.fields.basic.gadget': 'Устройство (терминал):',
  'new_units.objects.fields.basic.groups': 'Групи',
  'new_units.objects.fields.basic.imei': 'IMEI:',
  'new_units.objects.fields.basic.name': 'Име на имота:',
  'new_units.objects.fields.basic.note': 'Не е необходимо да посочвате името на устройството. ',
  'new_units.objects.fields.basic.port': 'Порт:',
  'new_units.objects.fields.basic.protocol': 'Протокол за обмен на данни:',
  'new_units.objects.fields.basic.server': 'Сървър за получаване на данни:',
  'new_units.objects.fields.basic.sim_1': 'Sim1:',
  'new_units.objects.fields.basic.sim_2': 'Sim2:',
  'new_units.objects.fields.basic.type': 'Тип обект:',
  'new_units.objects.fields.couplings.date_filter': 'Филтриране по дата:',
  'new_units.objects.fields.couplings.end': 'от',
  'new_units.objects.fields.couplings.end_date': 'Дата на завършване:',
  'new_units.objects.fields.couplings.errors.already_deleted': 'Вече сте изтрили тази връзка, ако искате да я върнете, щракнете върху бутона за отказ по-долу.',
  'new_units.objects.fields.couplings.errors.approved': 'Моля, потвърдете текущия си трейлър, за да създадете нова закачка!',
  'new_units.objects.fields.couplings.errors.couplig_end': 'Изберете дата на завършване!',
  'new_units.objects.fields.couplings.errors.couplig_start': 'Изберете начална дата!',
  'new_units.objects.fields.couplings.errors.trailer': 'Изберете трейлър!',
  'new_units.objects.fields.couplings.errors.work_type': 'Изберете вида работа!',
  'new_units.objects.fields.couplings.max_speed': 'Макс. ',
  'new_units.objects.fields.couplings.min_speed': 'Мин. ',
  'new_units.objects.fields.couplings.no_couplings': 'Не се предвижда работа с ремаркета',
  'new_units.objects.fields.couplings.note': 'Интервалът на използване на ремаркето се припокрива с друг интервал.',
  'new_units.objects.fields.couplings.sensor_mask': 'Маска на името на сензора:',
  'new_units.objects.fields.couplings.start': 'СЪС',
  'new_units.objects.fields.couplings.start_date': 'Начална дата:',
  'new_units.objects.fields.couplings.success.approved': 'Свързването беше успешно потвърдено!',
  'new_units.objects.fields.couplings.success.updated': 'Тегличът е актуализиран успешно',
  'new_units.objects.fields.couplings.table_columns.from': 'СЪС',
  'new_units.objects.fields.couplings.table_columns.to': 'от',
  'new_units.objects.fields.couplings.table_columns.trailer': 'Ремарке',
  'new_units.objects.fields.couplings.title': 'Планиране:',
  'new_units.objects.fields.couplings.trailer': 'Ремарке',
  'new_units.objects.fields.couplings.work_type': 'Вид работа:',
  'new_units.objects.fields.parameters.errors.correct_values': 'Попълнете правилните стойности, за да отидете в секцията!',
  'new_units.objects.fields.parameters.errors.wrong_value': 'Неправилна стойност!',
  'new_units.objects.fields.parameters.name': 'Име:',
  'new_units.objects.fields.parameters.no_parameters': 'Не сте добавили допълнителни параметри',
  'new_units.objects.fields.parameters.switch.hint': 'Показване в мониторинга',
  'new_units.objects.fields.parameters.switch.not_show_annotation': 'Не показвайте в анотация',
  'new_units.objects.fields.parameters.switch.show_annotation': 'Показване в анотация',
  'new_units.objects.fields.parameters.tabs.additional': 'Допълнително',
  'new_units.objects.fields.parameters.tabs.engine_hours': 'Моточасове',
  'new_units.objects.fields.parameters.tabs.filter_data': 'Филтриране на данни',
  'new_units.objects.fields.parameters.tabs.fuel': 'гориво',
  'new_units.objects.fields.parameters.tabs.parameters': 'Вашите параметри',
  'new_units.objects.fields.parameters.tabs.trips': 'Пътувания',
  'new_units.objects.fields.parameters.tabs.video': 'Видео',
  'new_units.objects.fields.parameters.value': 'Значение:',
  'new_units.objects.fields.parameters.video.hint': 'Въведете номерата на каналите, разделени със запетаи, за да покажете видеоклипа.',
  'new_units.objects.fields.sensors.fields.connected_sensors.bundle_with_ignition': 'Връзка към датчика за запалване',
  'new_units.objects.fields.sensors.fields.connected_sensors.cancel': 'Отказ',
  'new_units.objects.fields.sensors.fields.connected_sensors.connected': 'Обвързани',
  'new_units.objects.fields.sensors.fields.connected_sensors.title': 'Връзка със сензори',
  'new_units.objects.fields.sensors.fields.errors.duplicate_lower_bound': 'Стойностите на долната граница не могат да се повтарят!',
  'new_units.objects.fields.sensors.fields.errors.duplicate_x': 'X стойностите не могат да се повтарят!',
  'new_units.objects.fields.sensors.fields.errors.formula': 'Изберете формула!',
  'new_units.objects.fields.sensors.fields.errors.invalid_value': 'Не можете да добавите нов ред, защото в таблицата има невалидна стойност.',
  'new_units.objects.fields.sensors.fields.errors.name': 'Въведете името на сензора!',
  'new_units.objects.fields.sensors.fields.errors.parameter_1': 'Изберете опция 1!',
  'new_units.objects.fields.sensors.fields.errors.parameter_2': 'Изберете вариант 2!',
  'new_units.objects.fields.sensors.fields.errors.save_validation': 'Коригирайте грешката в текущия датчик, за да спестите!',
  'new_units.objects.fields.sensors.fields.errors.smoothing': 'Моля, въведете правилната стойност!',
  'new_units.objects.fields.sensors.fields.errors.type': 'Изберете тип сензор!',
  'new_units.objects.fields.sensors.fields.errors.validation': 'Коригирайте грешката в текущия сензор, за да преминете към друг!',
  'new_units.objects.fields.sensors.fields.expression': 'Изразяване',
  'new_units.objects.fields.sensors.fields.formula': 'Формула',
  'new_units.objects.fields.sensors.fields.help_info': 'С щракване на мишката изберете формата за работа с параметрите на сензора.',
  'new_units.objects.fields.sensors.fields.hidden_in_both': 'Скрити в отчети и мониторинг',
  'new_units.objects.fields.sensors.fields.hidden_in_monitoring': 'Скрит в мониторинга',
  'new_units.objects.fields.sensors.fields.hidden_in_report': 'Скрити в отчетите',
  'new_units.objects.fields.sensors.fields.hide_in_monitoring': 'Скрий се в наблюдението',
  'new_units.objects.fields.sensors.fields.hide_in_report': 'Скриване в отчетите',
  'new_units.objects.fields.sensors.fields.hint.hide': 'Крия',
  'new_units.objects.fields.sensors.fields.hint.show': 'Покажи',
  'new_units.objects.fields.sensors.fields.hint.title': 'Улика',
  'new_units.objects.fields.sensors.fields.multiple_deletion_mode': 'Вие сте в режим на премахване на сензора',
  'new_units.objects.fields.sensors.fields.name': 'Име:',
  'new_units.objects.fields.sensors.fields.new_sensor': 'Нов датчик',
  'new_units.objects.fields.sensors.fields.parameters.a': 'А',
  'new_units.objects.fields.sensors.fields.parameters.b': 'б',
  'new_units.objects.fields.sensors.fields.parameters.clear': 'Изчистете масата',
  'new_units.objects.fields.sensors.fields.parameters.download_as_csv': 'Изтеглете X,Y като CSV файл',
  'new_units.objects.fields.sensors.fields.parameters.download_from_csv': 'Заредете X и Y от CSV файл',
  'new_units.objects.fields.sensors.fields.parameters.exist': 'Яжте',
  'new_units.objects.fields.sensors.fields.parameters.helpers.degree': 'степен',
  'new_units.objects.fields.sensors.fields.parameters.helpers.param': 'Параметър',
  'new_units.objects.fields.sensors.fields.parameters.helpers.param1': 'param1',
  'new_units.objects.fields.sensors.fields.parameters.helpers.param1_minus_1': 'параметър1[-1]',
  'new_units.objects.fields.sensors.fields.parameters.helpers.param2': 'параметър2',
  'new_units.objects.fields.sensors.fields.parameters.helpers.param2_minus_1': 'параметър2[-1]',
  'new_units.objects.fields.sensors.fields.parameters.helpers.param_minus_1': 'Параметър[-1]',
  'new_units.objects.fields.sensors.fields.parameters.helpers.sensor_name': 'Име на болницата',
  'new_units.objects.fields.sensors.fields.parameters.helpers_description.byte_control': 'побитово управление на параметър (например param101:3 - третият бит от параметъра param101)',
  'new_units.objects.fields.sensors.fields.parameters.helpers_description.param': 'достъп до стойностите на всеки параметър от необработени данни',
  'new_units.objects.fields.sensors.fields.parameters.helpers_description.param1': 'достъп до първия параметър (след линейна трансформация) на текущия сензор',
  'new_units.objects.fields.sensors.fields.parameters.helpers_description.param1_minus_1': 'достъп до предишната стойност на първия параметър (след линейна трансформация) на текущия сензор',
  'new_units.objects.fields.sensors.fields.parameters.helpers_description.param2': 'достъп до втория параметър (след линейна трансформация) на текущия сензор',
  'new_units.objects.fields.sensors.fields.parameters.helpers_description.param2_minus_1': 'достъп до предишната стойност на втория параметър (след линейна трансформация) на текущия сензор',
  'new_units.objects.fields.sensors.fields.parameters.helpers_description.param_minus_1': 'достъп до предишната стойност на параметъра от необработени данни',
  'new_units.objects.fields.sensors.fields.parameters.helpers_description.sensor_name': 'достъп до стойността на други сензори',
  'new_units.objects.fields.sensors.fields.parameters.hide_graph': 'Скриване на диаграмата',
  'new_units.objects.fields.sensors.fields.parameters.lower_bound_short': '(долен ръб)',
  'new_units.objects.fields.sensors.fields.parameters.lower_bound_x': 'Долна граница X:',
  'new_units.objects.fields.sensors.fields.parameters.param_option_1': 'Параметър 1:',
  'new_units.objects.fields.sensors.fields.parameters.param_option_2': 'Параметър 2:',
  'new_units.objects.fields.sensors.fields.parameters.param_title_1': 'Параметър 1',
  'new_units.objects.fields.sensors.fields.parameters.param_title_2': 'Параметър 2',
  'new_units.objects.fields.sensors.fields.parameters.param_title_s_1': 'S1',
  'new_units.objects.fields.sensors.fields.parameters.param_title_s_2': 'S2',
  'new_units.objects.fields.sensors.fields.parameters.related_tables': 'Свързани таблици',
  'new_units.objects.fields.sensors.fields.parameters.sensor_calibration': 'Калибриране на сензора',
  'new_units.objects.fields.sensors.fields.parameters.show_graph': 'Показване на графика',
  'new_units.objects.fields.sensors.fields.parameters.smoothing_coefficient': 'Изглаждащ фактор',
  'new_units.objects.fields.sensors.fields.parameters.smoothing_coefficient_range': '(0 до 999)',
  'new_units.objects.fields.sensors.fields.parameters.type_filter': 'Тип филтрация',
  'new_units.objects.fields.sensors.fields.parameters.upper_bound_x': 'Горна граница X:',
  'new_units.objects.fields.sensors.fields.parameters.x': 'х',
  'new_units.objects.fields.sensors.fields.parameters.y': 'Y',
  'new_units.objects.fields.sensors.fields.sensor_type': 'Тип сензор:',
  'new_units.objects.fields.sensors.fields.technical_params.add_params': 'Добавете техника. ',
  'new_units.objects.fields.sensors.fields.technical_params.errors.wrong_value': 'Неправилна стойност!',
  'new_units.objects.fields.sensors.fields.technical_params.show_annotation': 'Показване на параметър в анотация на обект.',
  'new_units.objects.fields.sensors.fields.technical_params.title': 'Технически спецификации',
  'new_units.objects.fields.sensors.no_sensors': 'В обекта не са създадени сензори',
  'new_units.objects.fields.sensors.output_text': 'Изходен текст',
  'new_units.objects.fields.sensors.title': 'Сензори:',
  'new_units.objects.fields.sensors.value': 'Значение',
  'new_units.objects.fields.sensors.value_to_sensor': 'Преобразуване на стойности в текст:',
  'new_units.objects.fields.sensors.value_to_sensor_text': 'Преобразувайте числовата стойност на сензора в текст. ',
  'new_units.objects.fields.service.days': 'Дни',
  'new_units.objects.fields.service.errors.already_deleted': 'Вече сте изтрили тази смяна, ако искате да я върнете, щракнете върху бутона за отказ по-долу.',
  'new_units.objects.fields.service.errors.checkbox': 'Изберете 1 от типовете интервали!',
  'new_units.objects.fields.service.errors.description': 'Въведете описание!',
  'new_units.objects.fields.service.errors.intervalDays': 'Въведете интервал (дни)!',
  'new_units.objects.fields.service.errors.intervalKm': 'Въведете текущия си пробег (км)!',
  'new_units.objects.fields.service.errors.intervalMotohours': 'Въведете интервал (моторни часове)!',
  'new_units.objects.fields.service.errors.lastDays': 'Изберете датата на последната поддръжка!',
  'new_units.objects.fields.service.errors.lastKm': 'Въведете текущия си пробег (км)!',
  'new_units.objects.fields.service.errors.lastMotohours': 'Въведете последно (време)!',
  'new_units.objects.fields.service.errors.name': 'Въведете заглавието!',
  'new_units.objects.fields.service.errors.validation': 'Моля, попълнете всички задължителни полета!',
  'new_units.objects.fields.service.fields.ativate_engine_hours': 'Активирайте часовия интервал на двигателя',
  'new_units.objects.fields.service.fields.current_mileage': 'техн. ',
  'new_units.objects.fields.service.fields.days_period': 'Активирайте интервал по ден',
  'new_units.objects.fields.service.fields.description': 'Описание:',
  'new_units.objects.fields.service.fields.engine_hours': 'Интервал (M/h):',
  'new_units.objects.fields.service.fields.interval': 'Интервал (км):',
  'new_units.objects.fields.service.fields.interval_days': 'Интервал в дни:',
  'new_units.objects.fields.service.fields.last_serviced': 'Дата на последна поддръжка:',
  'new_units.objects.fields.service.fields.last_times': 'Последен път:',
  'new_units.objects.fields.service.fields.mileage_period': 'Активирайте интервала на километража',
  'new_units.objects.fields.service.fields.mileage_period.alert': 'Докладът за поддръжка изисква добавяне на датчик за пробег',
  'new_units.objects.fields.service.fields.mileage_period.alertSecond': 'Докладът за поддръжка изисква добавяне на сензор за абсолютни часове на двигателя',
  'new_units.objects.fields.service.fields.service': 'Обслужване:',
  'new_units.objects.fields.service.mileage': 'Пробег/M.h.',
  'new_units.objects.fields.service.no_service': 'Списъкът TO е празен',
  'new_units.objects.fields.service.service': 'Обслужване',
  'new_units.objects.fields.service.table_columns.days': 'Дни',
  'new_units.objects.fields.service.table_columns.mileage': 'Пробег/M.h.',
  'new_units.objects.fields.service.table_columns.service': 'Обслужване',
  'new_units.objects.fields.service.title': 'ЧЕ:',
  'new_units.objects.fields.shifts.additionalFields': 'Допълнителни полета:',
  'new_units.objects.fields.shifts.driver': 'Шофьор',
  'new_units.objects.fields.shifts.end': 'от',
  'new_units.objects.fields.shifts.errors.already_deleted': 'Вече сте изтрили тази смяна, ако искате да я върнете, щракнете върху бутона за отказ по-долу.',
  'new_units.objects.fields.shifts.errors.approved': 'Моля, потвърдете текущата си смяна, за да създадете нова!',
  'new_units.objects.fields.shifts.errors.driver': 'Изберете шофьор!',
  'new_units.objects.fields.shifts.errors.shift_end': 'Изберете край на смяната!',
  'new_units.objects.fields.shifts.errors.shift_start': 'Изберете началото на вашата смяна!',
  'new_units.objects.fields.shifts.hide_old_shifts': 'Скриване на минали смени',
  'new_units.objects.fields.shifts.name': 'Име:',
  'new_units.objects.fields.shifts.no_shifts': 'Списъкът със смени е празен',
  'new_units.objects.fields.shifts.note': 'Интервалът на използване на смяна се припокрива с друг интервал.',
  'new_units.objects.fields.shifts.shift_end': 'Край на смяната:',
  'new_units.objects.fields.shifts.shift_start': 'Начало на смяна:',
  'new_units.objects.fields.shifts.start': 'СЪС',
  'new_units.objects.fields.shifts.success.approved': 'Промяната беше успешно потвърдена!',
  'new_units.objects.fields.shifts.success.updated': 'Смяната е успешно актуализирана!',
  'new_units.objects.fields.shifts.table_columns.driver': 'Шофьор',
  'new_units.objects.fields.shifts.table_columns.from': 'СЪС',
  'new_units.objects.fields.shifts.table_columns.to': 'от',
  'new_units.objects.fields.shifts.title': 'смени:',
  'new_units.objects.fields.shifts.value': 'Значение:',
  'new_units.objects.fill': 'Напълнете',
  'new_units.objects.no_objects': 'Няма добавени обекти към системата',
  'new_units.objects.object': 'Предмет:',
  'new_units.objects.saved': 'Запазено',
  'new_units.objects.search': 'Търсене по обекти',
  'new_units.objects.search_options.custom_fields': 'Добавете. ',
  'new_units.objects.search_options.events': 'събития',
  'new_units.objects.search_options.groups': 'Групи',
  'new_units.objects.search_options.imei': 'IMEI',
  'new_units.objects.search_options.name': 'Име',
  'new_units.objects.search_options.phonenumber': 'телефони',
  'new_units.objects.search_options.sensors_name': 'Имена на сензори',
  'new_units.objects.search_options.sensors_param': 'Параметър на сензорите',
  'new_units.objects.search_options.sensors_type': 'Видове сензори',
  'new_units.objects.success.delete': 'Обектите са изтрити успешно!',
  'new_units.objects.table_columns.imei': 'IMEI',
  'new_units.objects.table_columns.name': 'Име',
  'new_units.objects.table_columns.protocol': 'протокол',
  'new_units.objects.table_columns.sensors': 'Сензори',
  'new_units.objects.table_columns.sim_1_2': 'Sim 1.2',
  'new_units.objects.tabs.basic': 'Основен',
  'new_units.objects.tabs.blocked_text': 'Смяната, поддръжката и прикачването ще бъдат налични след запазване на обекта',
  'new_units.objects.tabs.couplings': 'Закачвания',
  'new_units.objects.tabs.parameters': 'Тези. ',
  'new_units.objects.tabs.sensors': 'Сензори',
  'new_units.objects.tabs.service': 'ЧЕ',
  'new_units.objects.tabs.shifts': 'смени',
  'new_units.placeholder.select': 'Изберете',
  'new_units.recover.button': 'Отказ от изтриването',
  'new_units.recover.removed.couplings': 'Премахнати прикачвания:',
  'new_units.recover.removed.parameters': 'Премахнати параметри:',
  'new_units.recover.removed.sensors': 'Премахнати сензори:',
  'new_units.recover.removed.service': 'Премахнато КЪМ:',
  'new_units.recover.removed.shifts': 'Премахнати смени:',
  'new_units.recover.removed.trailers': 'Премахнати трейлъри:',
  'new_units.save_modal.close': 'Затворете формата',
  'new_units.save_modal.continue': 'продължи',
  'new_units.save_modal.message': 'Обектът и данните са запазени успешно',
  'new_units.tabs.groups': 'Групи',
  'new_units.tabs.links': 'Връзки за достъп',
  'new_units.tabs.objects': 'Обекти',
  'new_units.tabs.trailers': 'Трейлъри',
  'new_units.titles.create': 'Създаване на обект',
  'new_units.titles.edit': 'Редактиране на обект',
  'new_units.titles.groups': 'Групов указател',
  'new_units.titles.links': 'Директория с връзки за достъп',
  'new_units.titles.objects': 'Указател на обекти',
  'new_units.titles.trailers': 'Директория с трейлъри',
  'new_units.trailers.additionalFields': 'Допълнителни полета:',
  'new_units.trailers.errors.already_deleted': 'Вече сте изтрили този трейлър, ако искате да го върнете, щракнете върху бутона за отказ по-долу.',
  'new_units.trailers.errors.approved': 'Моля, запазете текущия си трейлър, за да създадете нов!',
  'new_units.trailers.errors.name': 'Въведете вашето име!',
  'new_units.trailers.errors.validation': 'Попълнете всички задължителни полета, за да преминете към друг трейлър!',
  'new_units.trailers.fields.code': 'Код:',
  'new_units.trailers.fields.description': 'Описание:',
  'new_units.trailers.fields.length': 'ширина:',
  'new_units.trailers.fields.name': 'Име:',
  'new_units.trailers.fields.value': 'Значение:',
  'new_units.trailers.no_trailers': 'Няма създаден трейлър',
  'new_units.trailers.save': 'Запазване',
  'new_units.trailers.search': 'Търсене по трейлъри',
  'new_units.trailers.search_options.code': 'Код',
  'new_units.trailers.search_options.name': 'Име',
  'new_units.trailers.success.approved': 'Трейлърът е създаден успешно!',
  'new_units.trailers.success.delete': 'Трейлърите са премахнати успешно!',
  'new_units.trailers.success.updated': 'Трейлърът е успешно актуализиран!',
  'new_units.trailers.table_columns.code': 'Код',
  'new_units.trailers.table_columns.name': 'Име',
  'new_units.trailers.table_columns.width': 'ширина',
  'new_units.trailers.title': 'Трейлъри:',
  'new_units.unspecified': 'неопределено',
  no: 'Не',
  noMatch: 'Няма намерени съвпадения',
  no_data: 'Няма данни',
  notification: 'уведомление',
  'notification.use_speed_description': 'Активирането на това поле за отметка няма да позволи на водача да ускори обекта над определената максимална скорост чрез натискане на педала на газта при влизане в геозоните, избрани по-долу.',
  'notification_alert.error_points': 'Няма точки за писта!',
  'notification_alert.success': 'Събитието е запазено успешно',
  'notifications.add': 'Добавете известие',
  'notifications.adress': 'Адрес',
  'notifications.allowedDowntime': 'Допустимо време на престой',
  'notifications.asEvent': 'Регистрирайте се като събития',
  'notifications.asViolation': 'Регистрирайте се като нарушение',
  'notifications.byEmail': 'Чрез имейл',
  'notifications.byPush': 'Push известия',
  'notifications.bySms': 'Чрез SMS',
  'notifications.byTelegram': 'В Telegram',
  'notifications.change': 'Редактиране на известието',
  'notifications.chooseDeliveryType': 'Не е избран тип доставка',
  'notifications.chooseEmail': 'Изберете поне 1 имейл!',
  'notifications.choosePhone': 'Изберете поне 1 телефон!',
  'notifications.chooseUser': 'Изберете поне един потребител',
  'notifications.connexionLost': 'Изгубена връзка',
  'notifications.control': 'контрол',
  'notifications.days_to_service': 'Брой дни закъснение за поддръжка',
  'notifications.delivery': 'Доставка',
  'notifications.deliveryMethod': 'Начин на доставка',
  'notifications.deviation': 'отклонение',
  'notifications.drain': 'Изцедете',
  'notifications.driver': 'Шофьор',
  'notifications.email.placeHolder': 'Въведете имейл',
  'notifications.enterName': 'Въведете вашето име!',
  'notifications.enterNotifyText': 'Въведете текст за известие!',
  'notifications.fill': 'Зареждам гориво',
  'notifications.final_fuel': 'Крайно гориво',
  'notifications.friday': 'петък',
  'notifications.from': 'с',
  'notifications.geozone_name': 'Име на геозона',
  'notifications.geozones.chooseVerficationType': 'Изберете тип тест!',
  'notifications.geozones.control': 'Контрол на геозона',
  'notifications.geozones.geozones': 'Геопрегради',
  'notifications.geozones.verificationType': 'Вид проверка',
  'notifications.hs_to_service': 'Броят часове на двигателя е надвишен',
  'notifications.km_to_service': 'Брой километри надвишен пробег',
  'notifications.kmph': 'км/ч',
  'notifications.latitude': 'Географска ширина',
  'notifications.longitude': 'Географска дължина',
  'notifications.lossDuration': 'Продължителност на загубата',
  'notifications.lostMsg': 'Изберете времеви интервал!',
  'notifications.main': 'Основи',
  'notifications.markAsRead': 'Маркирай като прочетено',
  'notifications.maxSpeed': 'Няма повече скорост',
  'notifications.min': 'мин',
  'notifications.monday': 'понеделник',
  'notifications.more': 'Допълнително',
  'notifications.motohours': 'По моточасове',
  'notifications.name': 'Име',
  'notifications.no_sound': 'Беззвучен',
  'notifications.notification': 'Известия',
  'notifications.notificationName': 'Заглавие на известието',
  'notifications.objectName': 'Име на обект',
  'notifications.objects': 'Обекти',
  'notifications.online': 'На линия',
  'notifications.paramTab': 'Таблица с параметри:',
  'notifications.period': 'Период',
  'notifications.periodcontrol': 'Контролен период спрямо текущото време',
  'notifications.phone.placeHolder': 'Въведете телефон...',
  'notifications.pointName': 'Име на точката',
  'notifications.push.placeHolder': 'Въведете име или имейл',
  'notifications.responsetime': 'Мин. ',
  'notifications.saturday': 'Събота',
  'notifications.sensor.control': 'Мониторинг на стойността на сензора',
  'notifications.sensor.controleGeo': 'Контрол на геозона',
  'notifications.sensor.maskByName': 'Маска по име',
  'notifications.sensor.meaning': 'Значение',
  'notifications.sensor.meaningFrom': 'Стойност от',
  'notifications.sensor.meaningTo': 'Стойност до',
  'notifications.sensor.minimalTime': 'Мин. ',
  'notifications.sensor.selectFrame': 'Изберете своите рамки!',
  'notifications.sensor.type': 'Тип сензор',
  'notifications.sensor.warning': 'Изберете тип сензор или маска по име!',
  'notifications.sensor.warningFromTo': 'Изберете от или до!',
  'notifications.sensor.work': 'Тригер',
  'notifications.sensor.writeNumber': 'Въведете номера',
  'notifications.sensorValue': 'Стойност на сензора',
  'notifications.sensors': 'Сензори',
  'notifications.service.byMilage': 'По пробег',
  'notifications.service.days': 'дни',
  'notifications.service.for': 'Отзад',
  'notifications.service.km': 'км',
  'notifications.service.maintenance': 'Уведомете, когато наближава планираната поддръжка',
  'notifications.service.warning': 'Въведете интервала по дни, пробег или моточасове!',
  'notifications.simpleTransport': 'Престой на превозното средство',
  'notifications.speed': 'Скорост',
  'notifications.sunday': 'неделя',
  'notifications.telegram.placeHolder': 'Въведете име или телеграма',
  'notifications.template': 'Шаблон за известие',
  'notifications.temporaryValueMonitoring': 'Контрол на временната стойност',
  'notifications.text': 'Текст',
  'notifications.text.arrival': 'Уведомяваме ви, когато планираната поддръжка наближава или закъснява в съоръжението %UNIT%.',
  'notifications.text.confirmation': 'Потребителите, които не са потвърдили имейла си, ще получат имейл с молба да го направят. ',
  'notifications.text.connection': 'Открита е загуба на връзка или координати %UNIT% в %TIME% близо до %ADDRESS%.',
  'notifications.text.drain': '%UNIT% за %TIME% източи %DRAIN% до %LAST_FUEL% близо до %ADDRESS%',
  'notifications.text.events_routes': 'Полетно събитие',
  'notifications.text.exit': '%UNIT% за %TIME% установи навлизане/излизане от геозона със скорост %SPEED% близо до %ADDRESS%',
  'notifications.text.filling': '%UNIT% за %TIME% презаредени с гориво на %FILL% до %LAST_FUEL% близо до %ADDRESS%',
  'notifications.text.geozone.inside': '%UNIT% за %TIME% откри навлизане в геозона %GEOZONE% със скорост %SPEED% близо до %ADDRESS%',
  'notifications.text.geozone.outside': '%UNIT% за %TIME% бе открито напускане на геозона %GEOZONE% със скорост %SPEED% близо до %ADDRESS%',
  'notifications.text.idle': 'Обект %UNIT% в %TIME% превиши ограничението за време на неактивност близо до %ADDRESS%',
  'notifications.text.sensor': '%UNIT% за %TIME% откри сензорно активиране при скорост %SPEED% близо до %ADDRESS%',
  'notifications.text.speed': '%UNIT% в %TIME% превиши максимално допустимото ограничение на скоростта със стойност %SPEED% близо до %ADDRESS%',
  'notifications.text.trip': '%UNIT% в %TIME% е записано полетно събитие близо до %ADDRESS%',
  'notifications.textDescription': 'Въведете текста на известието си, като използвате персонализираните опции по-долу. ',
  'notifications.thursday': 'четвъртък',
  'notifications.time': 'Време за реакция',
  'notifications.to': 'преди',
  'notifications.tripName': 'Име на полета',
  'notifications.trips.alertEvent': 'Предупредителни събития:',
  'notifications.trips.arrCheckPoint': 'Пристигане на контролни точки',
  'notifications.trips.departure': 'Тръгване от КПП',
  'notifications.trips.end': 'Край на полета',
  'notifications.trips.mask': 'Маска с името на полета',
  'notifications.trips.onlyViolation': 'Само при нарушение:',
  'notifications.trips.pass': 'Пропускане на контролни точки (строг ред)',
  'notifications.trips.start': 'Начало на полета',
  'notifications.trips.validationOne': 'Моля, въведете правилно името на услугата!',
  'notifications.tuesday': 'вторник',
  'notifications.types': 'Видове известия',
  'notifications.use_speed_limit': 'Ограничете възможността за овърклок',
  'notifications.velocity.geozones': 'Геопрегради',
  'notifications.velocity.max': 'Няма повече',
  'notifications.velocity.min': 'Не по-малко',
  'notifications.velocity.speed': 'Контрол на скоростта',
  'notifications.velocity.warning': 'Въведете скорост не повече или по-малко!',
  'notifications.view': 'Преглед',
  'notifications.warning': 'въведете минимална и максимална скорост',
  'notifications.wednesday': 'сряда',
  'notifications.workType': 'Вид работа',
  'notifications.service.controlService': 'Сервизен контрол',
  'notifications.success.title': 'Успех',
  'notifications.success.message': 'Известието е добавено успешно',
  'notifications.success.message2': 'Известието е актуализирано успешно',
  'notifications.one.object': 'Предмет',
  'notifications.more.object': 'Обект',
  'notifications.many.object': 'Обекти',
  'notifications.commands.warning': 'Трябва да посочите поне една команда за един от протоколите.',
  'notifications.commands.select_template': 'Прилагане на шаблон',
  'notifications.commands.send_command': 'Изпращане на команди',
  'notifications.commands.template_empty': 'Няма намерени шаблони',
  'objecrRecoveryTranslater.copyObject': 'Копиране на обект',
  'objecrRecoveryTranslater.objectCompany': 'обект във фирмата',
  'objecrRecoveryTranslater.restore': 'Наистина ли искате да възстановите?',
  'objecrRecoveryTranslater.restoring': 'Възстанови',
  'objecrRecoveryTranslater.success': 'обектът беше успешно реставриран',
  object: 'Предмет',
  objects: 'Предмет',
  'objects.add.objects': 'Добавете обекти',
  'objects.add.objects.in.group': 'Добавете обекти към група',
  'objects.add.units.to.group': 'Добавете обекти към група',
  'objects.create.edit.group': 'Редактиране на група',
  'objects.create.new.group': 'Създайте група',
  'objects.delete': 'Премахване',
  'objects.device': 'Тип устройство',
  'objects.editImei': 'Редактирайте избрания IMEI',
  'objects.fillfield': 'Необходимо е да попълните полетата в раздел Основни',
  'objects.groups': 'Групи',
  'objects.groups.access': 'Групови разрешения',
  'objects.groups.access-title': 'Групови права',
  'objects.groups.acess': 'Достъп',
  'objects.groups.close-all': 'Свиване на всички',
  'objects.groups.objects-in-group': 'Обекти в група',
  'objects.imei': 'IMEI',
  'objects.locator.access-object': 'Обект за достъп',
  'objects.locator.copy-link': 'Връзката е копирана',
  'objects.locator.status': 'Статус',
  'objects.locators': 'Връзки за достъп',
  'objects.locators-date-end': 'Крайна дата и час на достъп',
  'objects.locators-date-select': 'Изберете дата',
  'objects.locators-date-start': 'Начална дата и час на достъп',
  'objects.locators-link': 'Връзка',
  'objects.name': 'Въведете вашето име!',
  'objects.name.group': 'Име на групата',
  'objects.name.number': 'Име/Номер',
  'objects.objects': 'Обекти',
  'objects.showmonitoring': 'Показване в Мониторинг',
  'objects.title': 'Указател на обекти',
  'objects.titleLocators': 'Директория с връзки за достъп',
  'objects.titleTrailers': 'Директория с трейлъри',
  'objects.titlegroups': 'Групов указател',
  'objects.total.group': 'Брой обекти',
  'objects.trailers': 'Трейлъри',
  'objects.type': 'Тип обект',
  'objects.unit': 'Мерна единица',
  'objects.users': 'Потребители',
  'objectsSensors.cansel': 'Отказ',
  'objectsSensors.clearSelect': 'Изчистване на селекцията',
  'objectsSensors.copy': 'копие',
  'objectsSensors.copySensors': 'Сензори за копиране',
  'objectsSensors.noData': 'Няма данни',
  'objectsSensors.copy.success': 'Сензорите са копирани успешно',
  'objectsSensors.copy.error': 'Няма избрани сензори или обекти',
  'objectsSensors.copy.text1': 'Нови сензори се добавят към старите. ',
  'objectsSensors.copy.text2': 'Всички сензори за обекти ще бъдат изтрити и след това ще бъдат добавени копирани сензори.',
  'objectsSensors.copy.text3': 'Нови сензори се добавят към старите. Ако сред добавените сензори има имена, които дублират имената на сензорите на самия обект, тогава към имената на копираните сензори се добавя номер (започващ с единица).',
  "objects_locator_change": "Редактиране на връзка",
  "objects_locator_create": "Създайте връзка за достъп",
  "objects_noPicture": "Няма избрано изображение",
  "objects_phone1": "Телефон 1",
  "objects_phone2": "Телефон 2",
  "objects_port": "Порт:",
  "objects_server": "Сървър за получаване на данни:",
  "objects_tabs.TO": "ТОВА",
  "objects_tabs.drivers.add": "Добавете драйвер",
  "objects_tabs.drivers.change": "Смяна на драйвера",
  "objects_tabs.drivers.driver": "Шофьор",
  "objects_tabs.drivers.from": "СЪС",
  "objects_tabs.drivers.to": "от",
  "objects_tabs.extra": "Допълнителна",
  "objects_tabs.extra.addNewField": "Добавете ново поле",
  "objects_tabs.extra.changeField": "Промяна на полето",
  "objects_tabs.extra.custom": "По поръчка",
  "objects_tabs.extra.etc": "Допълнително",
  "objects_tabs.extra.fuel": "гориво",
  "objects_tabs.extra.motohours": "Часове на двигателя",
  "objects_tabs.extra.name": "Име",
  "objects_tabs.extra.trips": "Пътувания",
  "objects_tabs.extra.update_field": "Актуализирано поле",
  "objects_tabs.extra.value": "Смисъл",
  "objects_tabs.main": "Основен",
  "objects_tabs.main.analyst": "анализатор",
  "objects_tabs.main.analyst.error": "Изберете анализатор",
  "objects_tabs.main.device.error": "Изберете тип устройство",
  "objects_tabs.main.id.error": "Изберете уникален идентификатор",
  "objects_tabs.main.name.error": "Въведете име/номер",
  "objects_tabs.main.phone.error": "Въведете телефонен номер",
  "objects_tabs.main.type.error": "Изберете тип обект",
  "objects_tabs.sensor": "Сензори",
  "objects_tabs.sensors.actions": "Действия",
  "objects_tabs.sensors.border_bottom": "Долна граница",
  "objects_tabs.sensors.coefficient": "Коефициент",
  "objects_tabs.sensors.coefficientA": "Коефициент А",
  "objects_tabs.sensors.coefficientB": "Коефициент B",
  "objects_tabs.sensors.description": "Описание",
  "objects_tabs.sensors.enterValue": "Моля, въведете стойност!",
  "objects_tabs.sensors.error_formula": "Изберете формула!",
  "objects_tabs.sensors.error_param12": "Изберете опция 1 или опция 2!",
  "objects_tabs.sensors.error_type_sensor": "Въведете тип сензор!",
  "objects_tabs.sensors.formula": "Формула",
  "objects_tabs.sensors.formula.columns": "Формула на колона",
  "objects_tabs.sensors.hide_in_monitoring": "Скрий се в наблюдението",
  "objects_tabs.sensors.hide_in_reports": "Скриване в отчетите",
  "objects_tabs.sensors.keyExist": "Такъв ключ вече съществува!",
  "objects_tabs.sensors.lowerBorder": "Долна граница X",
  "objects_tabs.sensors.option1": "Параметър 1",
  "objects_tabs.sensors.option2": "Параметър 2",
  "objects_tabs.sensors.selectA": "Изберете стойност!",
  "objects_tabs.sensors.selectB": "Изберете b!",
  "objects_tabs.sensors.selectFromValue": "Изберете от Стойност!",
  "objects_tabs.sensors.selectValueX": "Изберете x стойност!",
  "objects_tabs.sensors.selectValueY": "Изберете стойност!",
  "objects_tabs.sensors.sensors_create": "Създаване на сензор",
  "objects_tabs.sensors.sensors_edit": "Редактиране на сензор",
  "objects_tabs.sensors.show_graph": "Показване на графика",
  "objects_tabs.sensors.show_table": "Показване на таблицата",
  "objects_tabs.sensors.smoothing": "Изглаждане",
  "objects_tabs.sensors.type_sensor": "Тип сензор",
  "objects_tabs.sensors.units": "Мерна единица",
  "objects_tabs.sensors.upperBorder": "Горна граница X",
  "objects_tabs.sensors.valueX": "X стойност",
  "objects_tabs.sensors.valueY": "Y стойност",
  "objects_tabs.services.active_on_interval_days": "Активирайте интервал в дни",
  "objects_tabs.services.active_on_interval_mileage": "Активирайте интервала на километража",
  "objects_tabs.services.create_form": "Създаване на поддръжка",
  "objects_tabs.services.date.placeholder": "Въведете датата!",
  "objects_tabs.services.description": "Описание",
  "objects_tabs.services.edit_form": "Редактиране ДО",
  "objects_tabs.services.intervalInDays": "Въведете интервала в дни!",
  "objects_tabs.services.intervalInMotoHours": "Активирайте часовия интервал на двигателя",
  "objects_tabs.services.intervalInMotoHoursDate": "Интервал по моточасове, ден/часове",
  "objects_tabs.services.interval_days": "Дни",
  "objects_tabs.services.interval_days_numb_day": "Интервал в дни, ден/дни",
  "objects_tabs.services.interval_mileage": "Пробег",
  "objects_tabs.services.interval_mileage_numb_km": "Интервал на пробег, брой/км",
  "objects_tabs.services.lastTime": "Въведете километража",
  "objects_tabs.services.lastTimeDate": "Последен път, дата/час",
  "objects_tabs.services.lastTimeDate.warning": "Влезте последния път!",
  "objects_tabs.services.last_one_date": "Последен път, дата",
  "objects_tabs.services.last_one_numb_km": "Последен път, брой/км",
  "objects_tabs.services.mileageInterval": "Въведете текущия си пробег",
  "objects_tabs.services.name_service": "Обслужване",
  "objects_tabs.services.warning.date": "Въведете датата!",
  "objects_tabs.services.warning.description": "Въведете описание!",
  "objects_tabs.services.warning.name": "Въведете имена на услуги!",
  "objects_tabs.trailer": "Трейлър",
  "objects_tabs.trailer.add": "„Добавяне на трейлър",
  "objects_tabs.trailer.change": "Промяна на трейлъра",
  "objects_tabs.trailers": "Трейлъри",
  "objects_trailer_change": "Промяна на трейлъра",
  "objects_trailer_create": "Създайте трейлър",
  "objects_trailer_key": "Ключ",
  "objects_trailer_moreFields": "Допълнителни полета",
  "one_element_deleted": "1 елемент премахнат",
  "objects.generate.imei": "Генерирайте виртуален IMEI",
  "outdated_version": "Версията е остаряла",
  "outdated_version_message": "Опреснете страницата",
  "pdf": "PDF",
  "phone": "Телефон",
  "phone_confirmation": "Телефонна проверка",
  "pixels.group": "екранни точки",
  "qrAdminTranslater.addGps": "Добавяне на GPS тракер",
  "qrAdminTranslater.print": "Печат",
  "qrAdminTranslater.qrCreate": "Създаване на QR код",
  "qrAdminTranslater.register": "Регистрация и",
  'qrAdminTranslater.scanCode': `Сканирайте кода, за да регистрирате GPS тракер или въведете кода на ${currentUrl}/c`,
  "report.tables.tabs.add_mask": "Добавете маска по име на сензора",
  "report.tables.tabs.add_parameter": "Добавете параметър",
  "report.tables.tabs.enter_value": "Въведете стойност",
  "report.tables.tabs.name_mask": "Маска на името на сензора",
  "report.tables.tabs.parameter": "Параметър",
  "report.tables.tabs.parameters": "Опции",
  "report.tables.tabs/parameter_hint": "Изберете имената на параметрите, които ще участват в генерирането на отчета.",
  "reportEdit.sensor.addTotalValue": "Добавете обща стойност в отчета:",
  "reportEdit.sensor.hintText": "Добавете получената стойност в реда с общи суми за тази колона в генерирания отчет.",
  "reports": "Доклади",
  "reports,tables.fields.mask_name_sensor": "Маска с името на сензора за гориво",
  "reports.action": "Действия",
  "reports.add-group-report": "Добавяне към шаблон",
  "reports.add-in-template": "Добавяне към шаблон",
  "reports.add-new-template": "Добавете нов шаблон",
  "reports.add-table-to-reports": "Добавете таблица от шаблон",
  "reports.applied_filter": "Приложено е филтриране от мониторинг.",
  "reports.attached": "Прикачен файл",
  "reports.base-table": "Основна маса",
  "reports.build-report": "Изграждане",
  "reports.chart.fillings": "Бензиностанции",
  "reports.chart.fillings_markers": "Интервали за зареждане с гориво",
  "reports.chart.intervals": "Интервали",
  "reports.chart.thefts": "сливи",
  "reports.chart.thefts_markers": "Интервали на източване",
  "reports.column": "Колона",
  "reports.composite_report": "Композитен отчет",
  "reports.connect": "Връзка към диаграма",
  "reports.contains": "съдържа",
  "reports.copy": "копие",
  "reports.copy_key": "Копирайте ключа в клетката на шаблона на Excel и след това заредете шаблона в SKIF.PRO",
  "reports.create_template": "Създайте шаблон",
  "reports.deleted_intervals_messages": "Интервали на изтрити съобщения",
  "reports.deleted_messaged": "Изтрити съобщения",
  "reports.display_all_events": "Покажи всички събития",
  "reports.download_selected": "Избрано натоварване",
  "reports.edit-report": "Редактиране",
  "reports.edit-reports": "Редактиране на параметрите на отчета",
  "reports.editTemplate.nameTemplate": "Име на шаблона",
  "reports.edit_template": "Промяна на шаблона",
  "reports.enter_name": "Въведете вашето име!",
  "reports.exclude_interval": "Премахнете интервала",
  "reports.form_for_unloading": "Форма за качване (шаблон на excel)",
  "reports.from_monitoring": "от Мониторинг",
  "reports.group-object": "Група от обекти",
  "reports.group-table": "Група маса",
  "reports.increase": "Увеличете",
  "reports.interval": "Интервал",
  "reports.interval_for_deletion": "Интервал за изтриване на съобщения",
  "reports.interval_info": "Интервалите на изтритите съобщения не се показват в дневника и не участват в генерирането на отчети.",
  "reports.is-already-template": "Съществуващ",
  "reports.key_hint": "Създайте шаблон за отчет в Excel, след което поставете данните в него, като копирате съответните ключове. ",
  "reports.link_to_magazine": "Линк към списанието",
  "reports.list_key": "Списък с ключове за шаблон на Excel",
  "reports.load-report": "Изтегляне",
  "reports.map_note": "Кликнете, за да видите точката на картата",
  "reports.map_snapshot": "Моментна снимка на картата",
  "reports.menu": "Меню",
  "reports.mini_hint": "След копиране изберете клетката на шаблона на Excel и натиснете CTRL+V.",
  "reports.moving": "Преместване",
  "reports.name-template": "Име на шаблона",
  "reports.new-temlate": "Нов",
  "reports.not-select": "Не е избрано",
  "reports.not_all_objects_displayed": "Не всички обекти се показват.",
  "reports.object": "Обект",
  "reports.onlyResult": "Показване само на суми",
  "reports.operation": "Операция",
  "reports.period": "Точка",
  "reports.print-report": "Печат",
  "reports.reduce": "Намаляване",
  "reports.reports-size-screen.maximum": "Цял екран",
  "reports.reports-size-screen.medium": "1/2",
  "reports.reports-size-screen.minimum": "1/4",
  "reports.reports-size-screen.thirdScreen": "1/3",
  "reports.reports-template": "Редактиране на шаблон",
  "reports.have-period-from-report": "Прилага се периода от отчета. ",
  "reports.today": "Днес",
  "reports.yesterday": "Вчера",
  "reports.week": "Тази седмица",
  "reports.month": "Този месец",
  "reports.reports-visit-geozone": "Доклад за посещение на геозона",
  "reports.reset_magnification": "Нулиране на увеличението",
  "reports.select": "Избор",
  "reports.select-group-object": "Избор на група обекти",
  "reports.select-object": "Избор на превозно средство",
  "reports.select-table": "Избор на маса",
  "reports.select-template": "Избор на шаблон за таблица",
  "reports.select_object": "Изберете обект!",
  "reports.select_objects_group": "Изберете група обекти!",
  "reports.select_with_magnification": "Избор на мащабиране",
  "reports.sensor_placeholder": "Сензорна маска",
  "reports.show-chart": "График",
  "reports.show-color": "Цвят на пистата",
  "reports.show_all_objects": "Покажи всички обекти",
  "reports.show_annotation_on_click": "Анотация чрез щракване върху ред",
  "reports.show_annotation_on_click_hint": "Ако е активирано, когато щракнете върху ред за отчет, се показва пояснение за събитието.",
  "reports.show_key": "Вижте списъка с ключове за шаблона на Excel",
  "reports.success_copy_template": "Шаблонът е успешно копиран.",
  "reports.tab-columns": "Колони",
  "reports.tab-geozones": "Геопрегради",
  "reports.tab-main": "генерал",
  "reports.tab-main.group": "Групиране",
  "reports.tab-main.group-field": "Групиране по поле",
  "reports.tab-main.interval-priority": "Приоритет на интервала на отчета",
  "reports.tab-main.interval-priority-tooltip": "Ако тази настройка е активна и интервалът се изрязва от началото или края на Shift, тогава той се включва в отчета в своята цялост.<br>Ако тази настройка не е активна, тогава точният интервал на смяна ще се появи в отчета.<br>Например, ако смяната започва в 08:00, а пътуването е започнало в 06:00,<br>след това, ако настройката „Приоритет на отчетните интервали“ е активна, интервалът в отчета за пътувания ще започне в 06:00,<br>и ако настройката “Приоритет на интервали на отчет” не е активна - то в 08:00ч.",
  "reports.tab-main.show-total": "Покажи общо",
  "reports.table.type": "Тип маса",
  "reports.tables": "Маси",
  "reports.tables.add_mask_sensor": "Добавете маска по име на сензора",
  "reports.tables.columns.fields.column": "Колона",
  "reports.tables.columns.fields.filter": "Филтър",
  "reports.tables.columns.fields.max_value": "Макс. ",
  "reports.tables.columns.fields.min_value": "Мин. ",
  "reports.tables.columns.fields.name.error": "Въведете име",
  "reports.tables.columns.fields.name.label": "Име на колона",
  "reports.tables.columns.fields.visibility": "Видимост",
  "reports.tables.fields.add_mask": "Добавете маска по име на сензора",
  "reports.tables.fields.excel_template_name": "Име на шаблон на Excel",
  "reports.tables.fields.files_warn": "Можете да качвате файлове само във формати xlsx xlsm xlsb",
  "reports.tables.fields.geozone_warn": "Трябва да изберете поне една входна геозона и една изходна геозона.",
  "reports.tables.fields.grouping": "Групиране",
  "reports.tables.fields.grouping_by_field": "Групиране по поле",
  "reports.tables.fields.name.error": "Въведете вашето име!",
  "reports.tables.fields.name.label": "Име",
  "reports.tables.fields.sensor_name_mask": "Маска на името на сензора",
  "reports.tables.fields.showThefts": "Показване на бензиностанции",
  "reports.tables.fields.showfillings": "Покажете сливи",
  "reports.tables.fields.template_upload": "Изтегляне",
  "reports.tables.geozones.enter": "Вход",
  "reports.tables.geozones.exit": "Изход",
  "reports.tables.geozones.title": "Геопрегради",
  "reports.tables.report": "Докладвай",
  "reports.tables.tabs.additional_columns": "Добавете. ",
  "reports.tables.tabs.all_protocol": "Всички протоколи",
  "reports.tables.tabs.columns": "Колони",
  "reports.tables.tabs.data": "данни",
  "reports.tables.tabs.general": "генерал",
  "reports.tables.tabs.geozones": "Геопрегради",
  "reports.tables.tabs.hint": "При затворени отчети потребителят не може да променя сензорите и параметрите, по които се изгражда.",
  "reports.tables.tabs.make_close_report": "Направете отчета личен",
  "reports.tables.tabs.mask_sensor": "Сензорни маски",
  "reports.tables.tabs.mask_sensor_hint": "За да генерирате отчет, изберете онези сензори за обекти, които ще задоволят поне една от посочените маски (прочетете повече на",
  "reports.tables.tabs.parameters": "Изберете опция",
  "reports.tables.tabs.sensors": "Сензори",
  "reports.tables.tabs.table_name": "Име:",
  "reports.tables.tabs.type_table": "Тип маса:",
  "reports.template": "проба",
  "reports.templateWarning": "Въведете име на шаблон!",
  "reports.template_deletion": "Изтриване на шаблон",
  "reports.template_deletion_sure": "Сигурни ли сте, че искате да изтриете шаблона за отчет?",
  "reports.value": "Смисъл",
  "reports.was-deleted": "беше изтрито",
  "reports.without_grouping": "Няма групиране",
  "reportsFilter.addInterval": "Добавете интервал",
  "reportsFilter.daysWeek": "Дни от седмицата:",
  "reportsFilter.filterByTime": "Филтриране по времеви интервали:",
  "reportsFilter.filterByGeozone": "Филтриране по геозони",
  "reportsFilter.hint.in": "Вътрешни геозони",
  "reportsFilter.hint.out": "Външни геозони",
  "reportsFilter.hint.in.text": "Отчетът показва само данните, получени в избраните геозони.",
  "reportsFilter.hint.out.text": "Отчетът показва само данните, получени извън избраните геозони.",
  "reportsFilter.filterType": "Тип филтрация:",
  "reportsFilter.filterHintFunction": "Ако функцията е активирана, състояние (например пътуване), което е започнало вътре в интервала, но е завършило извън него, ще бъде съкратено. ",
  "reportsFilter.filterHintSelectInterval": "Изберете времевите интервали, по които ще се филтрират данните в отчета.",
  "reportsFilter.title": "Филтриране",
  "reportsFilter.trimmerInterval": "Интервали на подрязване:",
  "reportsFilter.zeroInterval": "Нулев интервал!",
  "reportsFilter.groupBy": "Групи",
  "reportsFilter.addGroup": "Добавете групиране",
  "reportsFilter.byGroup.tooltip": "В момента можете да добавите не повече от три групи едновременно",
  "reportsFilter.byGroup.noSearch.msg": "Няма намерени групи",
  "reportsTemplate.successUpdate.msg": "Шаблонът е запазен успешно",
  "reports.loadingMoreData.msg": "Получаване на данни",
  "reportsTranslater.noData": "Няма данни за създаване на отчет",
  "reportsTranslater.rewrite": "Заявка",
  "response-terminal": "отговор",
  "retranslatorTranlsater.changeIMEI": "Замени с IMEI",
  "retranslatorTranlsater.gps_signal_category": "Категория на GPS сигнала:",
  "retranslatorTranlsater.hint": "Бавно се използва за тракери за обществен транспорт",
  "retranslatorTranlsater.participant": "ID на участник в програмата:",
  "retranslatorTranlsater.port": "Релеен порт",
  "retranslatorTranlsater.protocol": "протокол",
  "retranslatorTranlsater.retranslationFrom": "Повторно предаване от",
  "retranslatorTranlsater.retranslationTo": "Реле към",
  "retranslatorTranlsater.route_identifier": "ID на маршрута:",
  "retranslatorTranlsater.serverRetranslation": "Relay сървър",
  "retranslatorTranlsater.type_vehicle": "Тип обществено превозно средство:",
  "role": "Роля",
  "routesTrips.addTrip": "Добавете полет",
  "routesTrips.editTrip": "Редактиране на полет",
  "routesTrips.name": "Име",
  "routesTrips.route.addFromGeozones": "Добавете геозони",
  "routesTrips.route.addFromGeozones.title": "Добавете геозони",
  "routesTrips.route.addFromMap": "Добавете точка от картата",
  "routesTrips.route.create": "Създайте маршрут",
  "routesTrips.route.createGeozone.figure": "Фигура",
  "routesTrips.route.createGeozone.name": "Име",
  "routesTrips.route.createGeozone.title": "Добавете точка от картата",
  "routesTrips.route.createGeozone.width": "ширина",
  "routesTrips.route.edit": "Редактиране на маршрута",
  "routesTrips.route.name.placeholder": "Име на маршрута",
  "routesTrips.route.point": "Точка",
  "routesTrips.routes": "Маршрути",
  "routesTrips.title": "Маршрути и полети",
  "routesTrips.trip.activationType": "Тип активиране",
  "routesTrips.trip.activationType.tooltip": "Ако е избрано „по време на първата точка“, тогава началото на полета се фиксира стриктно според времето, посочено в първата контролна точка. ",
  "routesTrips.trip.addDays": "Добавете ден към следващите точки",
  "routesTrips.trip.addFromGeozones": "Добавете геозони",
  "routesTrips.trip.addFromGeozones.title": "Добавете геозони",
  "routesTrips.trip.addFromMap": "Добавете точка от картата",
  "routesTrips.trip.addFromRoutes": "Добавяне на пътни точки",
  "routesTrips.trip.addFromRoutes.title": "ДОБАВЯНЕ НА ТОЧКИ ОТ МАРШРУТА",
  "routesTrips.trip.arrival": "Пристигане",
  "routesTrips.trip.arrivalDev": "Отказ при пристигане",
  "routesTrips.trip.clearDays": "Изчистване на допълнителни ",
  "routesTrips.trip.createGeozone.figure": "Фигура",
  "routesTrips.trip.createGeozone.name": "Име",
  "routesTrips.trip.createGeozone.title": "Добавете точка от картата",
  "routesTrips.trip.date.cancel_days_period": "Изключете празниците",
  "routesTrips.trip.date.filter_days": "Филтърни дни",
  "routesTrips.trip.date.placeholder": "Изберете дати",
  "routesTrips.trip.date.reset_days_period": "Дни на периода на възстановяване",
  "routesTrips.trip.date.set_days_period": "Филтърни дни от периода",
  "routesTrips.trip.date.total_days_period": "Общо дни от периода",
  "routesTrips.trip.date.week_days.all_days": "Всички дни",
  "routesTrips.trip.date.week_days.even": "Даже",
  "routesTrips.trip.date.week_days.friday": "PT",
  "routesTrips.trip.date.week_days.monday": "пн",
  "routesTrips.trip.date.week_days.odd": "Дори не",
  "routesTrips.trip.date.week_days.saturday": "SB",
  "routesTrips.trip.date.week_days.sunday": "слънце",
  "routesTrips.trip.date.week_days.thursday": "чт",
  "routesTrips.trip.date.week_days.tuesday": "VT",
  "routesTrips.trip.date.week_days.wednesday": "SR",
  "routesTrips.trip.departure": "Заминаване",
  "routesTrips.trip.departureDev": "Отхвърляне на артикул",
  "routesTrips.trip.driver": "Шофьор",
  "routesTrips.trip.editGeozone.figure": "Фигура",
  "routesTrips.trip.editGeozone.name": "Име",
  "routesTrips.trip.editGeozone.title": "Редактиране на геозона",
  "routesTrips.trip.entranceToFirstPoint": "При влизане в първа точка",
  "routesTrips.trip.firstPoint": "Според времето на първата точка",
  "routesTrips.trip.leavingToFirstPoint": "При напускане на първа точка",
  "routesTrips.trip.name": "Име*",
  "routesTrips.trip.name.placeholder": "Име на полета...",
  "routesTrips.trip.object": "Обект*",
  "routesTrips.trip.object.placeholder": "Обект",
  "routesTrips.trip.order": "Ред на преминаване",
  "routesTrips.trip.order.arbitrary": "Произволно",
  "routesTrips.trip.order.strict": "Строг",
  "routesTrips.trip.period": "Точка",
  "routesTrips.trip.point": "Точка",
  "routesTrips.trip.pointsValidation": "Изберете поне две точки",
  "routesTrips.trip.removeDays": "Изтриване на дни към следващите точки",
  "routesTrips.trip.trailer": "Трейлър",
  "routesTrips.trip.validation": "Това поле е задължително",
  "routesTrips.trip.validation.total_period": "Трябва да добавите поне един общ ден",
  "routesTrips.trip.workType": "Вид работа",
  "routesTrips.trip.work_type_three": "Вид работа 3",
  "routesTrips.trip.work_type_two": "Вид работа 2",
  "routesTrips.trips": "Полети",
  "save": "Запазване",
  "search": "Търсене",
  "search_company": "Търсене по обекти",
  "select": "Изберете",
  "select_all": "Изберете всички",
  "send": "Изпратете",
  "send-terminal": "Изпращане",
  "sensorNotificationTranslater.inFrame": "В рамките на установените граници",
  "sensorNotificationTranslater.outFrame": "Извън установените рамки",
  "sensors_details.choose": "Изберете",
  "sensors_details.help_validator": "Валидаторът ще ви помогне да филтрирате или преобразувате стойности на сензора",
  "sensors_details.sensor_validator": "Валидатор на сензора:",
  "sensors_details.validation_info": "Мониторингът няма да покаже стойността на сензора, ако не е имало валидни данни повече от 3 дни.",
  "sensors_details.validation_principle": "Принцип на валидиране:",
  "sessions.day": "ден",
  "sessions.event": "Събитие",
  "sessions.ip": "IP адрес",
  "sessions.month": "месец",
  "sessions.time": "Дата/Час",
  "sessions.title": "Сесии",
  "sessions.user": "Потребител",
  "sessions.week": "седмица",
  "setting-company": "Настройки на компанията",
  "setting-user": "Потребителски настройки",
  "settings.balance.company": "фирма:",
  "settings.balance.currency": "търкайте",
  "settings.balance.errors.amount": "Въведете сумата за допълване",
  "settings.balance.min_amount": "Минимална сума за плащане",
  "settings.balance.mir_unavailable": "MIR картите временно не са достъпни за запазване",
  "settings.balance.save_card": "Запазване на картата",
  "settings.balance.top_up": "Допълнете",
  "settings.balance.top_up_amount": "Сума за допълване",
  "settings.balance.top_up_title": "Попълване",
  "settings.balance.turn_on_autopay": "Активиране на автоматично плащане",
  "settings.company.data_format": "Формат на датата:",
  "settings.company.exit_time": "Излезте след n минути неактивност",
  "settings.company.exit_time_tooltip": "0 или празно поле - няма да има изход поради бездействие",
  "settings.company.high_security_mode": "Режим на подобрена защита",
  "settings.company.high_security_mode_hint.first": "За да се увеличи сигурността, новите потребители ще трябва да създават силни пароли, когато активират този режим. ",
  "settings.company.high_security_mode_hint.second": "Когато променя паролата на потребител, той трябва да влезе с администраторската парола и след това да зададе своя собствена парола за акаунта. ",
  "settings.company.integrator": "Интегратор:",
  "settings.company.last_widget_time": "Час на последната дата в уиджета:",
  "settings.company.name": "Име:",
  "settings.company.note": "Посочете кой формат на часа ще се използва, когато избирате последната дата в диапазон от време (в изпълнимия модул за избор на дата).",
  "settings.company.retransmit_efis": "Повторно излъчване към EFIS",
  "settings.company.success.update_company": "Настройките на компанията са актуализирани успешно",
  "settings.company.time_format": "Формат на часа:",
  "settings.company.time_zone": "Часова зона:",
  "settings.company.title": "Настройки на компанията",
  "settings.display.area_measurement": "Площни единици:",
  "settings.display.geozones": "ГЕОЗОНИ",
  "settings.display.group_by_dots": "Групиране, ако разстоянието е по-малко от (екранни пиксели):",
  "settings.display.hectar": "ха",
  "settings.display.hide_events": "Скриване на събития на картата:",
  "settings.display.hide_notification": "Скриване на известията на картата:",
  "settings.display.kilometer": "км",
  "settings.display.map": "Карта",
  "settings.display.meter": "м",
  "settings.display.monitoring": "Мониторинг",
  "settings.display.notification": "Известия",
  "settings.display.object_grouping": "Групиране на обекти на картата:",
  "settings.display.show_annotation": "Показване на анотация при щракване върху обект в списъка за наблюдение:",
  "settings.display.show_geozones": "Показване на геозони на картата:",
  "settings.display.title": "Настройки на дисплея",
  "settings.display.track_weight": "Дебелината на коловоза на картата",
  "settings.invite.access_level": "Ниво на достъп:",
  "settings.invite.errors.access_level": "Изберете ниво на достъп",
  "settings.invite.errors.user_email": "Въведете имейл",
  "settings.invite.submit_button": "Покани",
  "settings.invite.success.invite": "Потребителят бе поканен успешно",
  "settings.invite.title": "Поканете потребител",
  "settings.invite.user_email": "Имейл на потребителя:",
  "settings.invite.user_email_placeholder": "Въведете своя имейл",
  "settings.main.access_settings": "Настройки за достъп",
  "settings.main.add_balance": "Допълнете баланса",
  "settings.main.additional": "Допълнително:",
  "settings.main.company": "фирма:",
  "settings.main.company_settings": "Настройки на компанията",
  "settings.main.company_tariff": "Фирмена тарифа",
  "settings.main.display_settings": "Настройки на дисплея",
  "settings.main.exit": "Излезте",
  "settings.main.finance_and_balance": "Финанси и баланс:",
  "settings.main.financical_report": "Финансов отчет",
  "settings.main.go_admin_panel": "Отидете на администратор. ",
  "settings.main.go_admin_panel_beta": "Админ. ",
  "settings.main.go_back": "Връщане",
  "settings.main.invite_user": "Поканете потребител",
  "settings.main.news_and_updates": "Новини и актуализации",
  "settings.main.payments_history": "История на плащанията",
  "settings.main.reference": "Помощ (Wiki)",
  "settings.main.sessions": "Сесии",
  "settings.main.support": "поддръжка",
  "settings.main.work_with_company": "Работа с компанията:",
  "settings.support.contacts.email": "Електронна поща: monitoringplus@support.ru",
  "settings.support.contacts.phone": "Телефон (само делнични дни от 9:00 до 18:00 ч.): 89008887712",
  "settings.support.contacts.telegram": "Телеграма: @Monitoring_plus",
  "settings.support.description": "добър ден ",
  "settings.support.title": "поддръжка",
  "settings.tariff.accounting_type": "Вид счетоводство:",
  "settings.tariff.active_autopay": "Автоматичното плащане е активирано",
  "settings.tariff.autopay": "Автоматично плащане:",
  "settings.tariff.by_number_objects": "По брой обекти",
  "settings.tariff.by_number_objects_sent_data": "По броя на обектите, изпращащи данни",
  "settings.tariff.card": "карта:",
  "settings.tariff.card_section.add": "Добавяне на карта",
  "settings.tariff.card_section.add_note": "За да добавим карта за автоматично плащане, ще трябва да дебитираме 1 рубла от вас. ",
  "settings.tariff.card_section.change": "Смяна на карта за автоматично плащане",
  "settings.tariff.card_section.continue": "Продължи",
  "settings.tariff.card_section.errors.mir_error": "Автоматичното плащане временно не е налично с карти MIR",
  "settings.tariff.card_section.mir_system": "MIR система",
  "settings.tariff.card_section.select_system": "*Изберете система за плащане:",
  "settings.tariff.card_section.temporarily_unavailable": "Временно недостъпен",
  "settings.tariff.card_section.visa_master": "Visa или Mastercard",
  "settings.tariff.errors.autopay": "Функцията за автоматично плащане е деактивирана, тъй като в картата няма достатъчно средства за завършване на транзакцията.",
  "settings.tariff.last_period_sum": "Сума за предходен период",
  "settings.tariff.next_write_off": "Следното отписване:",
  "settings.tariff.no_card_added": "Картата не е добавена",
  "settings.tariff.object_count": "Макс. ",
  "settings.tariff.object_price": "Цена на 1 обект:",
  "settings.tariff.on_balance": "По сметка (салдо):",
  "settings.tariff.payment_period": "Период на плащане:",
  "settings.tariff.period_start": "Начало на текущия период:",
  "settings.tariff.success.delete_card": "Картата е изтрита успешно",
  "settings.tariff.success.update_autopay": "Автоматичното плащане е актуализирано успешно",
  "settings.tariff.title": "Информация за тарифите",
  "settings.tariff.turn_on_autopay": "Активиране на автоматично плащане",
  "settings.user.access_level": "Ниво на достъп:",
  "settings.user.change_access_level": "Промяна на нивото на достъп",
  "settings.user.change_password": "Промяна на паролата",
  "settings.user.code": "Код:",
  "settings.user.description": "Описание:",
  "settings.user.edit": "Редактиране",
  "settings.user.email": "Имейл:",
  "settings.user.errors.admin_strong_pass": "Паролата трябва да е между 15 и 25 знака и да се състои от поне една главна английска буква, поне една малка английска буква и поне един специален знак (~ ! @ # $ % ^ & * ( ) - = + [ \"{ ] \" } | : \" \" , < . > /?)",
  "settings.user.errors.email": "Въведете имейл",
  "settings.user.errors.name": "Въведете име",
  "settings.user.errors.phone_confirmation": "Телефонният номер не е потвърден.",
  "settings.user.errors.simple_pass": "Паролата трябва да е между 5 и 25 знака и да съдържа поне една английска буква и поне една цифра",
  "settings.user.errors.user_strong_pass": "Паролата трябва да е между 8 и 25 знака и да се състои от поне една главна английска буква, поне една малка английска буква и поне един специален знак (~ ! @ # $ % ^ & * ( ) - = + [ \"{ ] \" } | : \" \" , < . > /?)",
  "settings.user.language": "език:",
  "settings.user.mailing_id": "Пощенски идентификатори за Telegram:",
  "settings.user.name": "Име:",
  "settings.user.note.first": "1) Влезте в бота на телеграма: t.me/skif2_bot",
  "settings.user.note.second": "2) Кликнете върху Старт, ботът връща пощенския идентификатор за Telegram",
  "settings.user.phone": "телефон:",
  "settings.user.set_password": "Задайте парола",
  "settings.user.success.avatar_change": "Аватарът беше успешно актуализиран",
  "settings.user.success.change_password": "Паролата бе променена успешно",
  "settings.user.success.update_info": "Информацията е актуализирана успешно",
  "settings.user.user": "потребител:",
  "status-terminal": "Статус",
  "success": "Успех",
  "tariff.accept": "Съгласен",
  "tariff.amount": "Сума по сметката",
  "tariff.amounttopay": "Сума",
  "tariff.balance": "По сметка:",
  "tariff.blocked-info": "Няма достатъчно средства по сметката на дружеството. ",
  "tariff.choose": "Избор на тарифен план",
  "tariff.choose-pay": "ИЗБЕРЕТЕ И ПЛАТЕТЕ ТАРИФАТА",
  "tariff.choose-units": "Изберете необходимия брой обекти:",
  "tariff.company-blocked": "фирмата е блокирана",
  "tariff.currency": "вал",
  "tariff.currency-month": "валута/месец",
  "tariff.currency-type": "валути",
  "tariff.current": "Вашата текуща тарифа:",
  "tariff.date-time": "Дата/Час",
  "tariff.days": "дни",
  "tariff.info-one": "Цената на 1 обект в системата СКИФ ПРО е",
  "tariff.info-two--part-one": "на",
  "tariff.info-two--part-two": "календарни дни.",
  "tariff.information": "ТАРИФНА ИНФОРМАЦИЯ",
  "tariff.kz": "KZT",
  "tariff.less_than_four_days": "ден",
  "tariff.next-payment": "Следното отписване:",
  "tariff.notselected": "Не е избрано",
  "tariff.objects": "Обекти",
  "tariff.offers": "предлага.",
  "tariff.one_day": "ден",
  "tariff.operation": "Операция",
  "tariff.paid": "Платен период",
  "tariff.pay": "ПЛАТИ ТАРИФАТА",
  "tariff.payableto": "за плащане:",
  "tariff.payement-date": "Плащане:",
  "tariff.payment-history": "ИСТОРИЯ НА ТАКСИ И ПЛАЩАНИЯ",
  "tariff.payments-history": "История на плащанията",
  "tariff.price": "Тарифна цена:",
  "tariff.remaining": "ляво:",
  "tariff.rouble": "търкайте",
  "tariff.tariffprice": "Тарифна цена:",
  "tariff.trial": "Пробен период:",
  "tariff.txt-five": "трябва да се свържете с поддръжката.",
  "tariff.txt-four": "Ако искате да намалите тарифата, ще го направите",
  "tariff.txt-one": "Ползването на системата за мониторинг SKIF PRO се заплаща.",
  "tariff.txt-seven": "тарифа, правила за плащане и",
  "tariff.txt-six": "Като щракнете върху бутона „Съгласен съм“, вие се съгласявате",
  "tariff.txt-three": "Първите 7 дни са безплатни.",
  "tariff.txt-two": "Можете да изберете тарифата, необходима за вашата компания.",
  "tariff.units": "обекти",
  "templateCommandTranslater.add": "Добавяне на команден шаблон",
  "templateCommandTranslater.edit": "Редактиране на команден шаблон",
  "templateCommandTranslater.enterNameCommand": "Въведете името на отбора",
  "templateCommandTranslater.enterTextCommand": "Въведете текст на командата",
  "templateCommandTranslater.nameCommand": "Име на отбора",
  "templateCommandTranslater.parametrsCommand": "Опции на командите",
  "templateCommandTranslater.selectCommand": "ИЗБЕРЕТЕ ОТБОР",
  "templateCommandTranslater.selectTypeTerminal": "Изберете тип терминал",
  "templateCommandTranslater.textCommand": "Текст на командата",
  "templateCommandTranslater.typeTermial": "Тип терминал",
  "text-command-terminal": "Текст на командата",
  "time": "време",
  "to": "включено",
  "tools.admin_panel_beta": "бета тест",
  "tools.admin_panel_v2": "Админ. ",
  "tracking.speed.legend.text": "Градация на скоростта",
  "tracking.speed.legend.mileage": "Пробег",
  "tracking.speed.legend.km": "км.",
  "trailer.width": "ширина на ремаркето (метър)",
  "translation.key": "стойност",
  "type": "Тип",
  "units.create": "Създаване на обект",
  "units.update": "Редактиране на обект",
  "unitsVisiblityTranslater.filterFormMonitoring": "Приложено е филтриране от мониторинг.",
  "unitsVisiblityTranslater.notShowAllObject": "Не всички обекти се показват.",
  "unitsVisiblityTranslater.showAllObject": "Покажи всички обекти",
  "user.create_user": "Създаване на потребител",
  "user.edit_user": "Редактиране на потребител",
  "user.enter_language": "Въведете вашия език!",
  "user.history_change": "История на промените",
  "user.invite_all": "Покана за всички фирми",
  "user.invite_email": "Имейл",
  "user.invite_role": "Роля",
  "user.invite_role_error": "Въведете роля!",
  "user.invite_user": "Покани",
  "users.approve_close_form": "Промените не бяха запазени. ",
  "users.change_password": "промяна",
  "users.change_password_title": "Промяна на паролата",
  "users.close_form": "Затваряне на профила",
  "users.confirm": "Потвърдете",
  "users.confirm_link": "Ще бъде изпратено съобщение с връзка. ",
  "users.confirm_message": "ще бъде изпратено писмо с молба да потвърдите съгласието си да получавате информационни съобщения от системата SKIF.PRO.",
  "users.confirm_notification": "Потребителят няма да получава известия по имейл, докато имейлът не бъде потвърден.",
  "users.current_password": "Текуща парола",
  "users.email": "поща",
  "users.email_placeholder": "Въведете своя имейл",
  "users.enter": "Вход",
  "users.exit": "Изход",
  "users.hint.lang": "Изберете език",
  "users.hint.role": "Изберете потребителска роля",
  "users.invalid_email": "Имейлът не е потвърден.",
  "users.invalid_phone": "Телефонният номер не е потвърден.",
  "users.invite": "Покани",
  "users.invite_all": "Покана за всички фирми",
  "users.invite_level_access": "Ниво на достъп",
  "users.isDriver": "Шофьор",
  "users.name": "Име",
  "users.password_empty": "Ако оставите полето празно, ще получите потвърждение за промяната на вашата парола по имейл.",
  "users.sessions": "Сесии",
  "users.subscribe": "Поканете потребител",
  "users.success_email_send": "Писмото е изпратено успешно.",
  "users.success_message_send": "Съобщението е изпратено успешно.",
  "valid.text": "Попълнете полето",
  "value": "Смисъл",
  "word": "Слово",
  "work_company.create_object": "Създаване на обекти",
  "work_company.data": "Данни от терминала",
  "work_company.data_terminal.ste_3": "В отчета за дневник можете също да добавяте параметри и други сензори. ",
  "work_company.data_terminal.step_1": "Можете да преглеждате необработени „сурови“ данни от терминали в отчета за регистрационния файл. ",
  "work_company.data_terminal.step_2": "След това в прозореца, който се отваря, трябва да изберете обект, период и да кликнете върху дневника в списъка с отчети",
  "work_company.short_list.action_eight": "Когато избирате параметри, можете да ги персонализирате, за да направите това, щракнете върху зъбното колело до желания параметър, въведете необходимите стойности и щракнете върху запазване",
  "work_company.short_list.action_five": "Забележка: След като изберете желания тип терминал, обърнете внимание на подканата, указваща сървъра и порта, където трябва да конфигурирате терминалите за изпращане на данни към сървъра",
  "work_company.short_list.action_four": "Във формата, която се отваря, попълнете всички полета и изберете изображение за обекта",
  "work_company.short_list.action_nine": "След като добавите и запазите сензора, щракнете върху създаване на обект",
  "work_company.short_list.action_seven": "След това трябва да кликнете върху „+“, ще се отвори прозорец за добавяне на сензор, в който трябва да попълните всички полета, да изберете параметри и формули",
  "work_company.short_list.action_six": "За да добавите сензор към обект, изберете „Сензори“ от горния падащ списък",
  "work_company.short_list.action_three": "Щракнете върху добавяне",
  "work_company.short_list.action_two": "За да отидете в административния панел от която и да е компания, щракнете върху бутона с потребителското име и след това върху елемента „Административен панел“ в избраната компания отидете в директорията на обекта",
  "work_company.title": "Работно място на компанията",
  "terminal.blocked": "Терминалът е блокиран",
  "yes": "да",
  "archive.title_settings": "Настройки",
  "archive.title_events": "Събития по обект",
  "archive.title": "Държавен архив",
  "archive.subtitle": "Обект",
  "archive.search": "Търсене",
  "archive.view": "Дисплей",
  "archive.add_state": "Добавете състояние",
  "archive.state_name": "Име на държавата",
  "archive.state_protocol": "протокол",
  "archive.state_date": "Активен от",
  "archive.state_imei": "IMEI",
  "archive.current": "Текущ",
  "archive.add_button": "Добавете",
  "archive.zebra": "Зебра",
  "archive.state_edit": "Редактиране",
  "archive.state_copy": "Копирайте и направете актуален",
  "archive.state_delete": "Изтриване",
  "archive.state_warning": "Редактирате архивираното състояние на обект.",
  "archive.state_save": "Запазете промените",
  "archive.state_cancel": "Отказ",
  "archives.groups_report": "Групов доклад",
  "archives.warning": "Някои обекти имат няколко архивни състояния за избрания период, поради което не могат да участват в генерирането на групов отчет и са изключени.",
  "archives.exclude": "Изключен от груповия отчет",
  "archives.requestReport": "Поискайте отчет",
  "trackColors.export": "Експортиране",
  "trackColors.limits": "Налични 7 нива на скорост",
  "trackColors.title": "Цвят на пистата",
  "trackColors.to": "към ",
  "trackColors.selected": "избрано: ",
  "archives.object.haveState": "Текущият обект съдържа състояния",
  "request.error.504": "Не успяхме да обработим заявката в приемлив срок. ",
  "mailing.tooltip": "Датата и часът на изпращане на първото съобщение ще бъдат изчислени в зависимост от избрания период и датата на последното изпращане.",
  "helper.monitoring.text_one": "Следете текущото състояние на автомобила.",
  "helper.monitoring.text_other": "Следете текущото състояние на автомобила.",
  "routesTrips.trip.work_type_one": "Вид работа 1",
  "routesTrips.trip.work_type_other": "Вид работа 1",
  "work_company.short_list.action_one": "За да създадете обекти, ще трябва да отидете в една от вашите компании. ",
  "work_company.short_list.action_other": "За да създадете обекти, ще трябва да отидете в една от вашите компании. ",
  'create.new_sensor': 'Създайте първия сензор',
  'create.copy.another_sensor': 'Копиране от друг обект',
  "copy_sensors.select.all": "Всички",
  "copy_sensors.select.name_object": "Име на обект",
  "copy_sensors.select.protocol": "протокол",
  "copy_sensors.select.IMEI": "IMEI",
  "copy_sensors.select.sim2": "sim2",
  "copy_sensors.select.sensors": "Сензори",
  "copy_sensors.select.groups": "Групи",
  'linear.measurement': 'Линейно измерване',
  'unitDisplays.format.acres': 'акра',
  'unitDisplays.format.feet': 'фута',
  'unitDisplays.format.kilometers': 'км',
  'unitDisplays.format.hectares': 'ха',
  'unitDisplays.format.meters': 'м',
  'unitDisplays.format.miles': 'мили',
  'unitDisplays.format.sqfeet': 'фута²',
  'unitDisplays.format.sqmeters': 'м²',
  'unitDisplays.format.sqmiles': 'мили²',
  'create.user.download.icon': 'Икона за изтегляне',
  'create.user.password': '*Парола за потребител',
  'create.user.password.repeat': '*Парола отново',
  'create.user.password.sameAs': 'Паролите трябва да съвпадат',
  'create.user.password.rules.high': 'Паролата трябва да е с дължина между 15 и 25 знака и да се състои от поне една главна английска буква, поне една малка английска буква и поне един специален знак (~ ! @ # $ % ^ & * ( ) - = + [ \'{ ] \'} |: \' ", < . > / ?)',
  'create.user.password.rules.middle': 'Паролата трябва да е с дължина между 8 и 25 знака и да се състои от поне една главна английска буква, поне една малка английска буква и поне един специален знак (~ ! @ # $ % ^ & * ( ) - = + [ \'{ ] \'} |: \' ", < . > / ?)',
  'create.user.password.rules.easy': 'Паролата трябва да е между 5 и 25 знака и да съдържа поне една английска буква и поне едно число',
  'create.user.not.required': 'Полета по избор',
  'reports.table.placeholder': 'Търсене по таблици',
  'access.settings.title': 'Настройки за достъп',
  'access.settings.groups': 'Групи за достъп',
  'access.settings.users': 'Потребители (групи)',
  'access.settings.name.role': 'Име на роля:',
  'access.settings.table.name': 'ОСНОВЕН',
  'access.settings.table.view': 'ПРЕГЛЕД',
  'access.settings.table.create.and.change': 'СЪЗДАВАНЕ И ПРОМЯНА',
  'access.settings.warning.save': 'Внимавайте, не сте запазили промените си!',
  'access.settings.basic.title': 'Споделен достъп',
  'access.settings.users.count': 'Потребители:',
  'access.settings.create.role': 'Създаване на роля',
  'access.settings.create.role.title': 'Заглавие на ролята',
  'access.settings.create.role.specify': 'Посочете името на ролята',
  'access.settings.access.in.group': 'Достъпи на потребителите в групата',
  'access.settings.create.user': 'Добавяне на потребител',
  'access.settings.create.btn': 'Добавено',
  'access.settings.create.btn.add': 'Добавить',
  'access.settings.access.on.group': 'Потребителски достъп по групи',
  'access.settings.access.group': 'ГРУПА',
  'access.settings.access.objects': 'ОБЕКТИ:',
  'access.settings.access.add.in.group': 'Добавяне към група',
  'access.modal.delete.role.title': 'Изтриване на роля',
  'access.modal.delete.role': 'Роля: ',
  'access.modal.delete.role.change': 'Заместваща роля: ',
  'access.modal.delete.role.btn.cancel': 'Отказ',
  'access.modal.delete.role.btn.delete': 'Изтриване на роля',
  'access.modal.delete.role.description.first': 'На потребителите с роля',
  'access.modal.delete.role.description.second': 'ще бъде назначена нова роля (посочете я по-горе).',
  'access.modal.delete.role.description.third': 'Потребители с роля',
  'access.settings.tooltip.change.role': 'Промяна на ролята на',
  'access.settings.tooltip.edit.role': 'Редактиране на роля',
  'access.settings.tooltip.delete.from.group': 'Изтриване от група',
  'access.settings.tooltip.delete.user': 'Изтриване на потребител',
  'access.settings.access.users.essence': 'Потребителски достъп до обекти',
  'access.settings.access.users.warning': 'Достъпът се конфигурира чрез добавяне на обект към група.',
}

export default translation
